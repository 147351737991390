import React, { useState, useEffect } from 'react';
import axiosInstance from '../api/axiosInstance';
import { Box, Typography, TextField, Button, Snackbar, Alert } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsersCog } from '@fortawesome/free-solid-svg-icons';

const UserProfile = () => {
  const [user, setUser] = useState(null);
  const [userid, setUserid] = useState(null);
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastSeverity, setToastSeverity] = useState('success'); // 'success' or 'error'
  const [isEditing, setIsEditing] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    fetchUserProfile();
  }, []);

  const fetchUserProfile = async () => {
    try {
      const response = await axiosInstance.get('/users/me');
      setUser(response.data);
      setUsername(response.data.username);
      setEmail(response.data.email);
      setUserid(response.data.id);
    } catch (error) {
      console.error('Error fetching user profile:', error);
    }
  };

  const handleSaveProfile = async () => {
    try {
      await axiosInstance.put('/users/me', { username, email });
      setToastMessage('Profile updated successfully!');
      setToastSeverity('success');
      setShowToast(true);
      setIsEditing(false);
      fetchUserProfile(); // Refresh user data
    } catch (error) {
      console.error('Error updating profile:', error);
      setToastMessage('Failed to update profile. Please try again.');
      setToastSeverity('error');
      setShowToast(true);
    }
  };

  const validatePassword = (password) => {
    const minLength = 7;
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\W).+$/; // At least one lowercase, one uppercase, and one special character
    if (password.length < minLength) {
      return `Password must be at least ${minLength} characters.`;
    }
    if (!regex.test(password)) {
      return 'Password must include uppercase, lowercase, and special characters.';
    }
    return ''; // No error
  };

  const handleChangePasswordProfile = async () => {
    // Validate passwords
    const newPasswordError = validatePassword(newPassword);
    if (newPasswordError) {
      setError(newPasswordError);
      return;
    }
  
    if (newPassword !== confirmPassword) {
      setError('Passwords do not match.');
      return;
    }
  
    try {
      await axiosInstance.put(`/users/${userid}/password`, { password: newPassword });
      setToastMessage('Success updating password');
      setShowToast(true);
      setToastSeverity('success');
      setNewPassword('');
      setConfirmPassword('');
      setError(''); // Clear any existing errors
    } catch (error) {
      console.error('Error updating password:', error);
      setError('Failed to update password. Please try again.');
    }
  };


  if (!user) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <Box sx={{ fontFamily: 'Cinzel, serif', p: 3 }}>
      <Box display="flex" alignItems="center" gap={2}  sx={{ mb: 3}}>
      <FontAwesomeIcon icon={faUsersCog} style={{ color: '#a52a2a', fontSize: '2rem', fontWeight: 'bold' }} />
        <Typography variant="h4" sx={{ fontFamily: 'Cinzel, serif', fontWeight: 'bold' }}>
          User Profile
        </Typography>
        </Box>
      <Box sx={{ fontFamily: 'Cinzel, serif' }}>
        <TextField
          label="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          disabled={!isEditing}
          fullWidth
          sx={{ mb: 2 }}
        />
        <TextField
          label="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          disabled={!isEditing}
          fullWidth
          sx={{ mb: 2 }}
        />
        <Typography variant="body1" sx={{ mb: 3, fontFamily: 'Cinzel, serif', fontWeight: 'bold' }}>
          Account Created: {new Date(user.created_at).toLocaleDateString()}
        </Typography>
        {isEditing ? (
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSaveProfile}
              sx={{ bgcolor: '#a52a2a', '&:hover': { bgcolor: '#8b0000' } }}
            >
              Save
            </Button>
            <Button
              variant="outlined"
              color="inherit"
              onClick={() => setIsEditing(false)}
            >
              Cancel
            </Button>
          </Box>
        ) : (
          <Button
            variant="contained"
            color="primary"
            onClick={() => setIsEditing(true)}
            sx={{ bgcolor: '#a52a2a', '&:hover': { bgcolor: '#8b0000' } }}
          >
            Edit Profile
          </Button>
        )}
      </Box>

      <Box sx={{ mt: 4 }}>
        <Typography variant="h6" sx={{ mb: 1, fontFamily: 'Cinzel, serif', fontWeight: 'bold' }}>
          Change Password
        </Typography>
        <Typography variant="p" sx={{ mb: 2, fontFamily: 'Cinzel, serif' }}>
        Password must be 7 characters and include uppercase, lowercase, and special characters.
        </Typography>
        {error && (
          <Typography color="error" sx={{ mt: 1, mb: 2 }}>
            {error}
          </Typography>
        )}
        <TextField
          label="New Password"
          type="password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          fullWidth
          sx={{ mt: 2, mb: 2 }}
        />
        <TextField
          label="Confirm Password"
          type="password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          fullWidth
          sx={{ mb: 3 }}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleChangePasswordProfile}
          sx={{ bgcolor: '#a52a2a', '&:hover': { bgcolor: '#8b0000' } }}
        >
          Update Password
        </Button>
      </Box>

      <Snackbar
        open={showToast}
        autoHideDuration={3000}
        onClose={() => setShowToast(false)}
      >
        <Alert
          onClose={() => setShowToast(false)}
          severity={toastSeverity}
          sx={{ width: '100%' }} 
        >
          {toastMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default UserProfile;
