//Pages/Login.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../api/axiosInstance';
import { Container, Typography, Box, TextField, Button } from '@mui/material';
import logo from '../assets/logo.png';
import TrackPageView from '../components/TrackPageView'; // Import TrackPageView

const Login = () => {
  const [isRegister, setIsRegister] = useState(false);
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [secretAnswer, setSecretAnswer] = useState('');
  const [error, setError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const navigate = useNavigate();
  const [isCodeRequired, setIsCodeRequired] = React.useState(false);

  useEffect(() => {
    if (localStorage.getItem('token')) {
      navigate('/dashboard');
    }
  }, [navigate]);

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const configResponse = await axiosInstance.get('/registration/config');
        setIsCodeRequired(configResponse.data.is_code_required);
      } catch (error) {
        console.error('Error fetching registration config:', error);
      }
    };

    fetchConfig();
  }, []);

  const validatePassword = (password) => {
    const minLength = 7;
    const regex = /^(?=.*[a-z])(?=.*[A-Z]).+$/; // Requires at least one lowercase and one uppercase letter
    if (password.length < minLength) {
      return `Password must be at least ${minLength} characters long.`;
    }
    if (!regex.test(password)) {
      return 'Password must include uppercase, lowercase, and special characters.';
    }
    return '';
  };



  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (isRegister) {
      // Validate password
      const passwordValidationError = validatePassword(password);
      if (passwordValidationError) {
        setPasswordError(passwordValidationError);
        return;
      } else {
        setPasswordError('');
      }
  
      try {
        // Fetch registration config to check if secret code is required
        const configResponse = await axiosInstance.get('/registration/config');
        const { is_code_required } = configResponse.data;
  
        if (is_code_required) {
          // Validate secret answer if required
          const secretResponse = await axiosInstance.post('/registration/check-secret', {
            secretAnswer,
          });
  
          if (!secretResponse.data.success) {
            setError(secretResponse.data.message || 'Incorrect secret code');
            return;
          }
        }
  
        // Proceed with registration
        await axiosInstance.post('/auth/register', {
          username,
          email,
          password,
          user_role: 'User',
        });
  
        setError('Registration successful! Please log in.');
        setIsRegister(false);
      } catch (error) {
        console.error('Registration failed:', error);
        setError('Registration failed. Please try again.');
      }
    } else {
      try {
        // Handle login
        const response = await axiosInstance.post('/auth/login', {
          email,
          password,
        });
  
        localStorage.setItem('token', response.data.token);
        window.dispatchEvent(new Event('storage'));
        navigate('/dashboard');
      } catch (error) {
        console.error('Login failed:', error);
        setError('Invalid email or password');
      }
    }
  };
  
  return (
    <Container maxWidth="xs" sx={{ mt: 4, fontFamily: 'Cinzel, serif' }}>
      <TrackPageView pageName="Login" />
      <Box
        sx={{
          p: 3,
          mt: 3,
          border: '1px solid #ddd',
          borderRadius: 2,
          boxShadow: 6,
          textAlign: 'center',
        }}
      >
        <Box
          component="img"
          src={logo}
          alt="Scribe & Scroll Logo"
          sx={{
            height: { xs: 40, sm: 50, md: 55 },
            mt: { xs: 1, sm: 1, md: 1 },
            ml: { xs: 1, sm: 1, md: 1 },
            mb: 2,
          }}
        />
        <Typography
          variant="h5"
          gutterBottom
          sx={{
            color: '#3d3d3d',
            fontWeight: 'bold',
            fontFamily: 'Cinzel, serif',
            mb: 2,
          }}
        >
          {isRegister ? 'User Registration' : 'User Login'}
        </Typography>
        <Typography
          variant="p"
          gutterBottom
          sx={{ color: '#3d3d3d', fontFamily: 'Cinzel, serif' }}
        >
          {isRegister
            ? 'Enter your details below to register a new account with Scribe & Scroll.'
            : 'Enter your email address and password below to login to Scribe & Scroll.'}
        </Typography>
        {error && (
          <Typography variant="body2" sx={{ color: 'red', mb: 2 }}>
            {error}
          </Typography>
        )}
      <Box component="form" onSubmit={handleSubmit} sx={{ display: 'flex', flexDirection: 'column' }}>
        {isRegister && (
          <>
            <TextField
              label="Username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
              fullWidth
              margin="normal"
            />
            {isCodeRequired && (
              <TextField
                label="Secret Answer"
                value={secretAnswer}
                onChange={(e) => setSecretAnswer(e.target.value)}
                required
                fullWidth
                margin="normal"
              />
            )}
          </>
        )}
        <TextField
          label="Email"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          fullWidth
          margin="normal"
        />
        <TextField
          label="Password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
          fullWidth
          margin="normal"
          error={Boolean(passwordError)}
          helperText={passwordError}
        />
        <Button
          type="submit"
          variant="contained"
          fullWidth
          sx={{ mt: 2, bgcolor: '#a52a2a', '&:hover': { bgcolor: '#8b0000' } }}
        >
          {isRegister ? 'Register' : 'Login'}
        </Button>
      </Box>
        <Typography
          variant="body2"
          onClick={() => navigate('/forgot-password')}
          sx={{
            mt: 2,
            color: '#007BFF',
            cursor: 'pointer',
            textDecoration: 'underline',
          }}
        >
          Forgot your password?
        </Typography>
        <Typography
          variant="body2"
          onClick={() => setIsRegister(!isRegister)}
          sx={{
            mt: 2,
            color: '#007BFF',
            cursor: 'pointer',
            textDecoration: 'underline',
          }}
        >
          {isRegister
            ? 'Already have an account? Log in'
            : 'New user? Register here'}
        </Typography>
      </Box>
    </Container>
  );
};

export default Login;
