import React, { useState, useEffect } from 'react';
import { Box, Typography, TextField, Button } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import axiosInstance from '../api/axiosInstance';

const RegistrationConfig = ({ setShowToast }) => {
  const [isCodeRequired, setIsCodeRequired] = useState(false);
  const [registrationCode, setRegistrationCode] = useState('');

  useEffect(() => {
    fetchRegistrationConfig();
  }, []);

  const fetchRegistrationConfig = async () => {
    try {
      const response = await axiosInstance.get('/registration/config');
      setIsCodeRequired(response.data.is_code_required);
      setRegistrationCode(response.data.secret_code || '');
    } catch (error) {
      console.error('Error fetching registration config:', error);
    }
  };

  const updateRegistrationConfig = async () => {
    try {
      await axiosInstance.put('/registration/config', {
        is_code_required: isCodeRequired,
        registration_code: registrationCode,
      });
      setShowToast(true);
    } catch (error) {
      console.error('Error updating registration config:', error);
    }
  };

  return (
    <Box sx={{ fontFamily: 'Cinzel, serif' }}>
      <Box display="flex" alignItems="center" gap={2} mb={2}>
        <FontAwesomeIcon icon={faLock} style={{ color: '#a52a2a', fontSize: '1.5rem', fontWeight: 'bold' }} />
        <Typography variant="h5" sx={{ fontWeight: 'bold', fontFamily: 'Cinzel, serif' }}>
          Registration Configuration
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mt: 2, mb: 3 }}>
        <Typography sx={{fontFamily: 'Cinzel, serif', fontWeight: 'bold'}}>Require Registration Code:</Typography>
        <Button
          variant="contained"
          onClick={() => setIsCodeRequired((prev) => !prev)}
          sx={{ bgcolor: isCodeRequired ? '#a52a2a' : 'gray', color: 'white' }}
        >
          {isCodeRequired ? 'Enabled' : 'Disabled'}
        </Button>
      </Box>
      <TextField
        label="Registration Code"
        value={registrationCode}
        onChange={(e) => setRegistrationCode(e.target.value)}
        fullWidth
      />

      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 4 }}>
        <Button
          variant="contained"
          onClick={updateRegistrationConfig}
          sx={{ width: '210px', bgcolor: '#a52a2a', '&:hover': { bgcolor: '#8b0000' } }}
        >
          Save Configuration
        </Button>
      </Box>
    </Box>
  );
};

export default RegistrationConfig;
