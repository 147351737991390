import React, { useState } from 'react';
import { Box, AppBar, Typography, Tab, Tabs, styled, Snackbar, Alert } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import AdminManageUsers from './AdminManageUsers';
import AdminRegistrationConfig from './AdminRegistrationConfig';

const StyledTabs = styled((props) => <Tabs {...props} />)(({ theme }) => ({
  '& .MuiTabs-indicator': {
    backgroundColor: '#a52a2a',
  },
  '& .MuiTab-root': {
    textTransform: 'none',
    fontSize: '16px',
    fontWeight: 'bold',
    color: theme.palette.text.secondary,
    '&:hover': {
      opacity: 1,
      color: theme.palette.text.primary,
    },
    '&.Mui-selected': {
      color: theme.palette.text.primary,
      borderBottom: `2px solid #a52a2a`,
    },
  },
}));

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: 'white', // Set the background color to white
}));

const AdminInterface = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const [showToast, setShowToast] = useState(false);

  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <Box display="flex" alignItems="center" gap={2} sx={{ mb: 3 }}>
        <FontAwesomeIcon icon={faCog} style={{ color: '#a52a2a', fontSize: '2rem', fontWeight: 'bold' }} />
        <Typography variant="h4" sx={{ fontFamily: 'Cinzel, serif', fontWeight: 'bold' }}>
          Admin Tools
        </Typography>
      </Box>
      <StyledAppBar position="static">
        <StyledTabs value={tabIndex} onChange={handleTabChange}>
          <Tab label="Manage Users" />
          <Tab label="Registration Code" />
        </StyledTabs>
      </StyledAppBar>
      <Box sx={{ flexGrow: 1, p: 3 }}>
        {tabIndex === 0 && <AdminManageUsers setShowToast={setShowToast} />}
        {tabIndex === 1 && <AdminRegistrationConfig setShowToast={setShowToast} />}
      </Box>
      <Snackbar open={showToast} autoHideDuration={3000} onClose={() => setShowToast(false)}>
        <Alert onClose={() => setShowToast(false)} severity="success" sx={{ width: '100%' }}>
          Action completed successfully!
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default AdminInterface;
