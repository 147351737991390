// components/SimpleHeader.js
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, AppBar, Toolbar, IconButton, Menu, MenuItem  } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import logo from '../assets/logo.png';

const SimpleHeader = ({ isLoggedIn }) => {
  const navigate = useNavigate();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const userIconRef = useRef(null); // Ref for user icon

  const handleLogout = () => {
    localStorage.removeItem('token');
    window.dispatchEvent(new Event('storage'));
    navigate('/login');
  };

  const goToDasahboard = () => {
    navigate('/dashboard');
    setIsDropdownOpen(false); // Close dropdown after navigating
  };

  const goToProfile = () => {
    navigate('/dashboard/profile');
    setIsDropdownOpen(false); // Close dropdown after navigating
  };
  // Close dropdown on click outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (userIconRef.current && !userIconRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <AppBar position="static" sx={{ bgcolor: '#efefee', p: 1,  borderBottom: '1px solid #D3D3D3' }}>
      <Toolbar sx={{ justifyContent: 'space-between' }}>
      <Box component="img" src={logo} alt="Scribe & Scroll Logo" sx={{ height: 55, cursor: 'pointer' }} onClick={() => navigate('/dashboard')} />
        {isLoggedIn && (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton
              ref={userIconRef}
              onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              sx={{
                color: '#a52a2a',
                bgcolor: '#ffffff',
                '&:hover': { bgcolor: '#c4c4c4' },
                borderRadius: '50%',
                padding: 1,
                transition: 'background-color 0.3s',
              }}
            >
              <FontAwesomeIcon icon={faUser} />
            </IconButton>
            <Menu
              anchorEl={userIconRef.current}
              open={isDropdownOpen}
              onClose={() => setIsDropdownOpen(false)}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
              sx={{ mt: 1}}
            >
              <MenuItem sx={{ color: '#000' }} onClick={goToDasahboard}>Dashboard</MenuItem>
              <MenuItem sx={{ color: '#000' }} onClick={goToProfile}>Profile</MenuItem>
              <MenuItem sx={{ color: '#000' }} onClick={handleLogout}>Logout</MenuItem>
            </Menu>
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default SimpleHeader;
