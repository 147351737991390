// App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Home from './pages/Home';
import FreeEbook from './pages/FreeEbook';
import Write from './pages/Write';
import Engage from './pages/Engage';
import Newsletter from './pages/Newsletter';
import About from './pages/About';
import Login from './pages/Login';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import ProtectedRoute from './components/ProtectedRoute';
import NavMenu from './components/NavMenu';
import AdminNewsletterEmails from './pages/AdminNewsletterEmails';
import AdminWriteBlog from './pages/AdminWriteBlog';
import AdminSiteContentEditor from './pages/AdminSiteContentEditor';
import BlogPage from './pages/BlogPage';
import Layout from './components/Layout';
import AdminInterface from './pages/AdminInterface';
import AdminAnalytics from './pages/AdminAnalytics';
import AdminAnnouncements from './pages/AdminAnnouncements';
import DynamicPage from './pages/DynamicPage';
import AdminCustomerContact from './pages/AdminCustomerContact';
import PrivacyTerms from './pages/PrivacyTerms';
import AdminNewsletterContent from './pages/AdminNewsletterContent';
import AnnouncementModal from './components/AnnouncementModal';
import UserProfile from './components/UserProfile';
import ContactDrawer from './components/ContactDrawer';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import './App.css';

function AnimatedRoutes({ setContentLoaded }) {
  const location = useLocation();
  const adminRoutes = ['/dashboard', '/login']; // limits where the contact bubble shows
  const isAdminPage = adminRoutes.some((route) => window.location.pathname.startsWith(route));
  
  useEffect(() => {
    // Reset contentLoaded on route change
    setContentLoaded(false);
  }, [location, setContentLoaded]);

  return (
    <>
      {!isAdminPage && <ContactDrawer />}
      <AnnouncementModal />
  
      {/* Routes without Animation */}
      <Routes>
        {/* Protected Routes */}
        <Route path="/dashboard" element={<ProtectedRoute><NavMenu /></ProtectedRoute>}>
          <Route path="adminnewsletteremails" element={<AdminNewsletterEmails />} />
          <Route path="adminwriteblog" element={<AdminWriteBlog />} />
          <Route path="adminsitecontenteditor" element={<AdminSiteContentEditor />} />
          <Route path="admininterface" element={<ProtectedRoute requiredRole="Admin"><AdminInterface /></ProtectedRoute>} />
          <Route path="adminanalytics" element={<AdminAnalytics />} />
          <Route path="adminannouncements" element={<AdminAnnouncements />} />
          <Route path="admincustomercontact" element={<AdminCustomerContact />} />
          <Route path="adminnewslettercontent" element={<AdminNewsletterContent />} />
          <Route path="profile" element={<UserProfile />} />
        </Route>
      </Routes>
  
      {/* Animated Routes */}
      <SwitchTransition>
        <CSSTransition
          key={location.key}
          classNames="fade"
          timeout={300} // Match this with the CSS duration
        >
          <Routes location={location}>
            <Route path="/" element={<Home setContentLoaded={setContentLoaded} />} />
            <Route path="/home" element={<Home setContentLoaded={setContentLoaded} />} />
            <Route path="/about" element={<About setContentLoaded={setContentLoaded} />} />
            <Route path="/free-ebook" element={<FreeEbook setContentLoaded={setContentLoaded} />} />
            <Route path="/write" element={<Write setContentLoaded={setContentLoaded} />} />
            <Route path="/engage" element={<Engage setContentLoaded={setContentLoaded} />} />
            <Route path="/blog" element={<Engage setContentLoaded={setContentLoaded} />} />
            <Route path="/newsletter" element={<Newsletter setContentLoaded={setContentLoaded} />} />
            <Route path="/blogs" element={<Engage setContentLoaded={setContentLoaded} />} />
            <Route path="/engage/:url" element={<BlogPage setContentLoaded={setContentLoaded} />} />
            <Route path="/blog/:url" element={<BlogPage setContentLoaded={setContentLoaded} />} />
            <Route path="/blogs/:url" element={<BlogPage setContentLoaded={setContentLoaded} />} />
            <Route path="/privacy-terms" element={<PrivacyTerms setContentLoaded={setContentLoaded} />} />
            <Route path="/login" element={<Login setContentLoaded={setContentLoaded} />} />
            <Route path="/forgot-password" element={<ForgotPassword setContentLoaded={setContentLoaded} />} />
            <Route path="/reset-password/:token" element={<ResetPassword setContentLoaded={setContentLoaded} />} />
            <Route path="*" element={<DynamicPage setContentLoaded={setContentLoaded} />} />
          </Routes>
        </CSSTransition>
      </SwitchTransition>
    </>
  );
  
}

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem('token'));
  const [contentLoaded, setContentLoaded] = useState(false);

  useEffect(() => {
    const handleStorageChange = () => {
      setIsLoggedIn(!!localStorage.getItem('token'));
    };

    window.addEventListener('storage', handleStorageChange);
    return () => window.removeEventListener('storage', handleStorageChange);
  }, []);

  return (
    <Router>
      <Layout isLoggedIn={isLoggedIn} contentLoaded={contentLoaded}>
        <AnimatedRoutes setContentLoaded={setContentLoaded} />
      </Layout>
    </Router>
  );
}

export default App; 
