// components/ContenEditor.js
import React from 'react';
import { Editor } from '@tinymce/tinymce-react';

const ContentEditor = ({ value, onEditorChange }) => {
  return (
    <Editor
      apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
      value={value}
      init={{
        height: '100%', 
        menubar: true,
        plugins: [
          'advlist',
          'autolink',
          'lists',
          'link',
          'image',
          'media',
          'charmap',
          'table',
        ],
        toolbar: `
          undo redo | formatselect | bold italic underline strikethrough | 
          forecolor backcolor removeformat | alignleft aligncenter alignright alignjustify | 
          bullist numlist outdent indent | link image media anchor | table
        `,
        content_style: "body { font-family: 'Cinzel', serif; }",
        font_formats: "Cinzel=Cinzel,serif;Arial=arial,helvetica,sans-serif;Courier New=courier new,courier,monospace;",
      }}
      onEditorChange={onEditorChange}
    />
  );
};

export default ContentEditor;