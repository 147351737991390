//Pages/AdminNewsletterContent.js
import React, { useState, useEffect } from 'react';
import { Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Switch, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, FormControlLabel, Checkbox } from '@mui/material';
import { Edit, Delete } from '@mui/icons-material';
import axiosInstance from '../api/axiosInstance';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenNib } from '@fortawesome/free-solid-svg-icons';
import { format } from 'date-fns';
import ContentEditor from '../components/ContentEditor';

const AdminNewsletterContent = () => {
  const [newsletterContent, setNewsletterContent] = useState([]);
  const [currentContent, setCurrentContent] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [contentToDelete, setContentToDelete] = useState(null);
  const [error, setError] = useState('');

  // Fetch newsletter content from the server
  const fetchNewsletterContent = async () => {
    try {
      const response = await axiosInstance.get('/newslettercontent');
      const sortedData = response.data.sort((a, b) => {
        const dateA = new Date(a.publish_date);
        const dateB = new Date(b.publish_date);
        return dateB - dateA;
      });
      setNewsletterContent(sortedData);
    } catch (error) {
      console.error('Failed to fetch newsletter content:', error);
    }
  };

  // Handle toggle for the active state only
  const handleToggleActive = async (id, is_active) => {
    try {
      // Optimistic update: Immediately reflect the change in UI
      setNewsletterContent((prev) =>
        prev.map((content) =>
          content.id === id ? { ...content, is_active: !is_active } : content
        )
      );

      // Send only the 'is_active' field in the PUT request
      const updatedContent = { is_active: !is_active };

      await axiosInstance.put(`/newslettercontent/${id}`, updatedContent);
    } catch (error) {
      console.error('Failed to update newsletter content active state:', error);
      // Revert to the previous state if the update fails
      setNewsletterContent((prev) =>
        prev.map((content) =>
          content.id === id ? { ...content, is_active } : content
        )
      );
    }
  };

  // Open the modal for adding or editing content
  const handleOpenModal = (content = null) => {
    setError('');
    setCurrentContent(
      content || {
        title: content?.title || '',
        newsletter_content: content?.newsletter_content || '',
        publish_date: content?.publish_date || '',
        is_active: content?.is_active || true,
      }
    );
    setIsEditing(!!content);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setCurrentContent(null);
    setIsEditing(false);
    setError('');
  };

  // Validate fields before saving
  const validateFields = () => {
    if (!currentContent?.title || !currentContent?.publish_date || !currentContent?.newsletter_content) {
      setError('Please fill in all fields (Title, Publish Date, and Content).');
      return false;
    }
    setError('');
    return true;
  };

  // Handle save button click
  const handleSaveClick = async () => {
    if (validateFields()) {
      handleSaveContent(); // Proceed with saving the content
    }
  };

  // Save content to the server
  const handleSaveContent = async () => {
    try {
      if (isEditing) {
        const updatedContent = {
          ...currentContent,
          publish_date: currentContent.publish_date,
        };
        await axiosInstance.put(`/newslettercontent/${currentContent.id}`, updatedContent);
      } else {
        const newContent = {
          ...currentContent,
          publish_date: currentContent.publish_date,
        };
        const response = await axiosInstance.post('/newslettercontent', newContent);
        setNewsletterContent((prev) => [...prev, response.data]);
      }
      fetchNewsletterContent();
      handleCloseModal();
    } catch (error) {
      console.error('Failed to save newsletter content:', error);
    }
  };

  // Open delete confirmation dialog
  const handleOpenDeleteDialog = (content) => {
    setContentToDelete(content);
    setIsDeleteDialogOpen(true);
  };

  // Close delete confirmation dialog
  const handleCloseDeleteDialog = () => {
    setIsDeleteDialogOpen(false);
    setContentToDelete(null);
  };

  // Confirm deletion of content
  const handleConfirmDelete = async () => {
    try {
      await axiosInstance.delete(`/newslettercontent/${contentToDelete.id}`);
      setNewsletterContent((prev) =>
        prev.filter((content) => content.id !== contentToDelete.id)
      );
      handleCloseDeleteDialog();
    } catch (error) {
      console.error('Failed to delete newsletter content:', error);
    }
  };

  useEffect(() => {
    fetchNewsletterContent();
  }, []);

  return (
    <Box sx={{ fontFamily: 'Cinzel, serif' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Box display="flex" alignItems="center" gap={2}>
          <FontAwesomeIcon icon={faPenNib} style={{ color: '#a52a2a', fontSize: '2rem', fontWeight: 'bold' }} />
          <Typography variant="h4" sx={{ fontFamily: 'Cinzel, serif', fontWeight: 'bold' }}>
            Newsletter Content Editor
          </Typography>
        </Box>
        <Button variant="contained" color="primary" onClick={() => handleOpenModal()}>
          Add Newsletter Content
        </Button>
      </Box>

      {/* Newsletter Content Table */}
      <TableContainer component={Paper}>
        <Table sx={{ fontFamily: 'Cinzel, serif' }}>
          <TableHead>
            <TableRow>
              <TableCell>Title</TableCell>
              <TableCell align="right">Publish Date</TableCell>
              <TableCell align="right">Active</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {newsletterContent.map((content) => (
              <TableRow key={content.id || `${content.title}-${content.publish_date}`}>
                <TableCell>{content.title}</TableCell>
                <TableCell align="right">{new Date(content.publish_date).toLocaleDateString()}</TableCell>
                <TableCell align="right">
                  <Switch
                    checked={content.is_active ?? false}
                    onChange={() => handleToggleActive(content.id, content.is_active)}
                    color="primary"
                  />
                </TableCell>
                <TableCell align="right">
                  <IconButton onClick={() => handleOpenModal(content)}>
                    <Edit color="primary" />
                  </IconButton>
                  <IconButton onClick={() => handleOpenDeleteDialog(content)}>
                    <Delete color="error" />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Add/Edit Modal */}
      <Dialog open={isModalOpen} onClose={handleCloseModal} fullWidth maxWidth="xl" disableEnforceFocus={true}
             sx={{
              '& .MuiDialog-paper': {
                width: '90%',
                height: '90vh',
                maxHeight: '100vh',
              },
            }}>
        <DialogTitle>{isEditing ? 'Edit Newsletter Content' : 'Add Newsletter Content'}</DialogTitle>
        <DialogContent>
          {/* Error Message */}
          {error && (
            <Typography color="error" variant="body2" sx={{ mt: 2 }}>
              {error}
            </Typography>
          )}
          <Box display="flex" alignItems="center" sx={{ gap: 2, mb: 2 }}>
            {/* Title field */}
            <TextField
              label="Title"
              value={currentContent?.title || ''}
              onChange={(e) =>
                setCurrentContent({
                  ...currentContent,
                  title: e.target.value,
                })
              }
              fullWidth
              margin="normal"
            />
            
            {/* Date field */}
            <TextField
              label="Publish Date"
              type="date"
              value={currentContent?.publish_date ? format(new Date(currentContent?.publish_date), 'yyyy-MM-dd') : ''}
              onChange={(e) =>
                setCurrentContent({
                  ...currentContent,
                  publish_date: e.target.value,
                })
              }
              sx={{ width: '250px' }}
              margin="normal"
            />
            
            {/* Active checkbox */}
            <FormControlLabel
              control={<Checkbox checked={currentContent?.is_active ?? false} onChange={(e) => setCurrentContent({ ...currentContent, is_active: e.target.checked })} />}
              label="Active"
              labelPlacement="end"
            />
          </Box>

          {/* Content Editor */}
          <ContentEditor
            value={currentContent?.newsletter_content || ''}
            onEditorChange={(content) => {
              setCurrentContent({
                ...currentContent,
                newsletter_content: content,
              });
            }}
            fullWidth
          />
        </DialogContent>

        <DialogActions>
          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
            <Button onClick={handleCloseModal} color="secondary" variant="contained">
              Cancel
            </Button>
            <Button onClick={handleSaveClick} color="primary" variant="contained">
              Save
            </Button>
          </Box>
        </DialogActions>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog open={isDeleteDialogOpen} onClose={handleCloseDeleteDialog}>
        <DialogTitle>Delete Newsletter Content</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this newsletter content?</Typography>
        </DialogContent>
        <DialogActions>
          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
            <Button onClick={handleCloseDeleteDialog} color="secondary" variant="contained">
              Cancel
            </Button>
            <Button onClick={handleConfirmDelete} color="error" variant="contained">
              Delete
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default AdminNewsletterContent;
