// components/header.js
import React, { useState, useEffect, useRef } from 'react';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import { AppBar, Toolbar, IconButton, MenuItem, Box, Typography, MenuList, Popper, Paper, Grow, ClickAwayListener, Drawer, List, ListItem, ListItemText } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import axiosInstance from '../api/axiosInstance';
import logo from '../assets/logo.png';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const Header = ({ isLoggedIn }) => {
  const navigate = useNavigate();
  const location = useLocation(); // Current path
  const [menuItems, setMenuItems] = useState([]);
  const [activeMenuIndex, setActiveMenuIndex] = useState(null); // Track hovered menu
  const anchorRefs = useRef([]); // Attach references to parent menu items
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false); // Track mobile menu state

  useEffect(() => {
    const fetchMenuItems = async () => {
      try {
        const response = await axiosInstance.get('/menu');
 
        if (Array.isArray(response.data)) {
          // Filter only active parent and child menu items
          const activeItems = response.data
            .filter((item) => item.active) // Include only active parent items
            .map((item) => ({
              ...item,
              children: item.children?.filter((child) => child.active), // Filter only active children
            }))
            .sort((a, b) => a.menu_order - b.menu_order); // Sort by menu_order
  
          setMenuItems(activeItems);
        } else {
          console.error('Menu items response is not an array:', response.data);
          setMenuItems([]);
        }
      } catch (error) {
        console.error('Failed to fetch menu items:', error);
        setMenuItems([]);
      }
    };
  
    fetchMenuItems();
  }, []);
  

  const handleMenuEnter = (index) => {
    setActiveMenuIndex(index);
  };

  const handleMenuLeave = () => {
    setActiveMenuIndex(null);
  };

  const handleToggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };


  // Helper function to determine if an item or its children are active
  const isItemActive = (item) => {
    if (location.pathname === item.path) {
      return true;
    }
    if (item.children && item.children.some((child) => location.pathname === child.path)) {
      return true;
    }
    return false;
  };

  return (
    <AppBar position="static" sx={{ bgcolor: '#ffffff', color: '#000' }}>
      <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', px: 2 }}>
        {/* Logo */}
        <Box
          component="img"
          src={logo}
          alt="Scribe & Scroll Logo"
          sx={{ height: { xs: 40, sm: 50, md: 55}, mt: { xs: 1, sm: 1, md: 1 }, ml: { xs: 1, sm: 1, md: 1 }, cursor: 'pointer' }}
          onClick={() => navigate('/')}
        />

        {/* Desktop Menu */}
        {!isMobile && (
          <Box sx={{ display: 'flex', gap: 0.1, alignItems: 'center' }}>
          {menuItems.map((item, index) => (
            <Box
              key={item.id}
              sx={{ position: 'relative' }}
              onMouseEnter={() => handleMenuEnter(index)}
              onMouseLeave={handleMenuLeave}
              ref={(el) => (anchorRefs.current[index] = el)} // Attach ref for Popper
            >
              <NavLink
                to={item.path} // Ensure parent is clickable
                style={{
                  textDecoration: 'none',
                  color: isItemActive(item) ? '#a52a2a' : '#000', // Active color
                  fontWeight: isItemActive(item) ? 'bolder' : 'bold',
                  fontSize: '1em',
                  textTransform: 'uppercase',
                  fontFamily: 'Cinzel, serif',
                }}
              >
            <Box
              sx={{
                borderBottom: isItemActive(item) || activeMenuIndex === index
                  ? '2px solid #a52a2a' : '2px solid transparent',
                '&:hover': {
                  color: '#a52a2a',
                  borderBottom: '2px solid #a52a2a', // Hover underline
                },
                '&:active': {
                  backgroundColor: '#ffe5e5', // Adds a subtle highlight when clicked
                  transform: 'scale(1.1)', // Slight increase on click
                },
                transition: 'color 0.3s, border-bottom 0.3s, transform 0.2s, background-color 0.2s',
                pb: 0.5,
                mr: .25,
                padding: '0.3em 0.8em', // Adjusted padding to reduce spacing
                display: 'inline-block', // Ensure padding doesn't affect layout
              }}
            >
              {item.page.toUpperCase()}
            </Box>
          </NavLink>
                {/* Submenu for Desktop */}
                {item.children.length > 0 && (
                  <Popper
                    open={activeMenuIndex === index}
                    anchorEl={anchorRefs.current[index]}
                    placement="bottom-start"
                    transition
                    disablePortal
                    sx={{ fontFamily: 'Cinzel, serif', textTransform: 'uppercase' }}
                  >
                    {({ TransitionProps }) => (
                      <Grow {...TransitionProps}>
                        <Paper>
                          <ClickAwayListener onClickAway={handleMenuLeave}>
                            <MenuList sx={{ fontFamily: 'Cinzel, serif' }}>
                              {item.children.map((child) => (
                                <MenuItem
                                  key={child.id}
                                  onClick={() => navigate(child.path)} // Navigate to child path
                                  sx={{
                                    fontFamily: 'Cinzel, serif',
                                    fontWeight: 'bold',
                                    fontSize: '0.85rem',
                                    color:
                                      location.pathname === child.path
                                        ? '#a52a2a'
                                        : '#000', // Highlight active child
                                  }}
                                >
                                  {child.page}
                                </MenuItem>
                              ))}
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                )}
              </Box>
            ))}
          </Box>
        )}

      {/* Mobile Menu */}
      {isMobile && (
        <IconButton color="inherit" onClick={handleToggleMobileMenu}>
          <MenuIcon sx={{ fontSize: '2.3rem', color: '#a52a2a' }}/>
        </IconButton>
      )}
      </Toolbar>

      {/* Mobile Drawer */}
      <Drawer
        anchor="left"
        open={mobileMenuOpen}
        onClose={handleToggleMobileMenu}
        sx={{ fontFamily: 'Cinzel, serif' }}
      >
       <Box
        component="img"
        onClick={handleToggleMobileMenu}
        src={logo}
        alt="Scribe & Scroll Logo"
        sx={{
          mt: 2,
          height: 45, // Adjust the height
          width: 'auto', // Maintain aspect ratio
          maxWidth: '100%', // Prevent it from exceeding the container width
          objectFit: 'contain', // Ensure it fits nicely
          cursor: 'pointer',
        }}
      />
         
         <Box sx={{ width: 250, bgcolor: '#ffffff', height: '100%', textTransform: 'uppercase', fontFamily: 'Cinzel, serif' }}>
  <List>
    {menuItems.map((item) => {
      const isParentActive = location.pathname === item.path; // Check if the parent is active
      const isChildActive = item.children.some((child) => location.pathname === child.path); // Check if any child is active

      return (
        <React.Fragment key={item.id}>
          {/* Parent Menu Item */}
          <ListItem
            button
            onClick={() => {
              navigate(item.path);
              handleToggleMobileMenu();
            }}
            sx={{
              color: isParentActive ? '#a52a2a' : '#000',
              backgroundColor: isParentActive ? '#e8e6e6' : '#ffffff',
              fontWeight: isParentActive ? 'bolder' : 'bold',
              borderRight: isParentActive && !isChildActive ? '3px solid #a52a2a' : 'transparent', // Border only if the parent is active and no child is active
              cursor: 'pointer',
              fontFamily: 'Cinzel, serif',
              '&:hover': { borderRight: location.pathname === item.path ? '3px solid #a52a2a' : '3px solid black'},

            }}
          >
            <ListItemText primary={item.page.toUpperCase()} />
          </ListItem>

          {/* Child Menu Items */}
          {item.children.length > 0 &&
            item.children.map((child) => (
              <ListItem
                button
                key={child.id}
                onClick={() => {
                  navigate(child.path);
                  handleToggleMobileMenu();
                }}
                sx={{
                  pl: 4, // Indent to show hierarchy
                  color: location.pathname === child.path ? '#a52a2a' : '#000',
                  cursor: 'pointer',
                  fontFamily: 'Cinzel, serif',
                  fontSize: '14px',
                  borderRight: !isParentActive && isChildActive && location.pathname === child.path ? '3px solid #a52a2a' : 'transparent', // Border only if the parent is active and no child is active
                  backgroundColor: location.pathname === child.path ? '#e8e6e6' : 'transparent', // Optional background for active child
                  '&:hover': { borderRight: location.pathname === child.path ? '3px solid #a52a2a' : '3px solid black'},

                }}
              >
                <ListItemText
                  sx={{
                    fontFamily: 'Cinzel, serif',
                    fontSize: '14px',
                  }}
                  primary={child.page}
                />
              </ListItem>
            ))}
        </React.Fragment>
      );
    })}
  </List>
</Box>
      </Drawer>

      {/* Subtitle */}
      <Box
        sx={{
          bgcolor: '#efefee',
          textAlign: 'center',
          py: { xs: 0.5, sm: 1 },
          mt: 1,
        }}
      >
        <Typography
          sx={{
            fontFamily: 'Cinzel, serif',
            letterSpacing: { xs: 3, sm: 5, md: 7 },
            color: '#000000',
            fontSize: { xs: '1.0rem', sm: '1.5rem', md: '2.3rem' },
            fontWeight: { xs: '600', sm: '525', md: '525' },
          }}
        >
          SCRIBE <span style={{ color: '#a52a2a' }}>&</span> SCROLL WRITING
        </Typography>
        <Typography
          sx={{
            fontFamily: 'Cinzel, serif',
            letterSpacing: { xs: 3, sm: 5, md: 7 },
            color: '#000000',
            fontSize: { xs: '.8rem', sm: '1.0rem', md: '1.5rem' },
            fontWeight: { xs: '550', sm: '500', md: '500' },
          }}
        >
          CREATIVE <span style={{ color: '#a52a2a' }}>CHRISTIAN</span> CONTENT
        </Typography>
      </Box>
    </AppBar>
  );
};

export default Header;


