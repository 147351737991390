// Pages/DynamicPage.js
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import axiosInstance from '../api/axiosInstance';
import { Container, Box } from '@mui/material';
import TrackPageView from '../components/TrackPageView';

const adminRoutes = ['/dashboard', '/adminsitecontenteditor', '/adminwriteblog','/adminannouncements','/adminnewslettercontent',
  '/adminnewsletteremails','/admincustomercontact','/adminanalytics','/admininterface','/profile', '/login','/forgot-password',
  '/password-reset','/profile',]; 

  
const DynamicPage = ({ setContentLoaded }) => {
  const location = useLocation();
  const [content, setContent] = useState('');
  const [pageTitle, setPageTitle] = useState(null);

  useEffect(() => {
    // Skip fetch if on an admin page
    if (adminRoutes.some((route) => location.pathname.startsWith(route))) {
      return; // Skip fetching content for admin routes
    }

    const fetchPageContent = async () => {
      setContentLoaded(false);
      try {
        const response = await axiosInstance.get('/content/dynamic/path', {
          params: { path: location.pathname },
        });
        setContent(response.data.content || '<p>No content available.</p>');
        setPageTitle(response.data.page || 'Untitled Page');
        setContentLoaded(true);
      } catch (error) {
        console.error(`Failed to fetch content for path '${location.pathname}':`, error);
        setPageTitle('Content Not Found');
      }
    };

    fetchPageContent();
  }, [location.pathname, setContentLoaded]);

  return (
    <Container maxWidth="xl" sx={{ mt: 2, px: 2, fontFamily: 'Cinzel, serif', bgcolor: '#fff' }}>
      {pageTitle && <TrackPageView pageName={pageTitle} />}
      <Box
        component="main"
        dangerouslySetInnerHTML={{ __html: content }}
        sx={{
          fontSize: { xs: '1rem', sm: '1.1rem' },
          fontFamily: 'Cinzel, serif',
          lineHeight: 1.6,
          '& h1, & h2': {
            color: '#a52a2a',
            textAlign: 'center',
            fontSize: { xs: '1.5rem', sm: '2rem' },
            mt: 2,
          },
          '& p': {
            my: 2,
            textAlign: { xs: 'center', sm: 'left' },
          },
          '& ul': {
            paddingLeft: 3,
            '& li': {
              mb: 1,
              fontWeight: 'bold',
            },
          },
          '& img': {
            maxWidth: '100%',
            height: 'auto',
            display: 'block',
            margin: '20px auto',
          },
        }}
      />
    </Container>
  );
};

export default DynamicPage;
