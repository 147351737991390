//Pages/AdminWriteBlog.js
import React, { useState, useEffect } from 'react';
import axiosInstance from '../api/axiosInstance';
import ContentEditor from '../components/ContentEditor';
import { Box, Button, Typography, TextField, Checkbox, FormControlLabel, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Dialog,  DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Snackbar, Alert, Stack } from '@mui/material';
import { Delete, Edit, Comment } from '@mui/icons-material';
import CancelIcon from '@mui/icons-material/Cancel';
import { format } from 'date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';

const AdminWriteBlog = () => {
  const [newBlog, setNewBlog] = useState({
    id: null,
    name: '',
    content: '',
    status: true,
    created_at: new Date().toISOString().split('T')[0],
    url: '',
    image: null,
    comments_enabled: true,
  });
  const [blogs, setBlogs] = useState([]);
  const [showToast, setShowToast] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);
  const [commentsModalOpen, setCommentsModalOpen] = useState(false);
  const [selectedBlogComments, setSelectedBlogComments] = useState([]);
  const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false);
  const [selectedBlogId, setSelectedBlogId] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    fetchBlogs();
  }, []);

  const resetBlogForm = () => {
    setNewBlog({
      id: null,
      name: '',
      content: '',
      status: true,
      created_at: new Date().toISOString().split('T')[0],
      url: '',
      image: null,
      comments_enabled: true,
    });
    setPreviewImage(null);
  };

  const fetchBlogs = async () => {
    try {
      const response = await axiosInstance.get('/blogs');
      setBlogs(response.data.sort((a, b) => a.name.localeCompare(b.name)));
    } catch (error) {
      console.error('Failed to fetch blogs:', error);
    }
  };

  const fetchComments = async (blogId) => {
    try {
      const response = await axiosInstance.get(`/blogs/${blogId}/comments`);
      setSelectedBlogComments(response.data);
      setCommentsModalOpen(true);
    } catch (error) {
      console.error('Failed to fetch comments:', error);
    }
  };

  const handleDeleteComment = async (commentId) => {
    try {
      await axiosInstance.delete(`/blogs/comments/${commentId}`);
      setSelectedBlogComments((prev) => prev.filter((comment) => comment.id !== commentId));
      setShowToast(true);
    } catch (error) {
      console.error('Failed to delete comment:', error);
    }
  };


  const handleTitleBlur = () => {
    const formattedUrl = newBlog.name.toLowerCase().replace(/\s+/g, '-').replace(/[^a-z0-9-]/g, '');
    setNewBlog((prev) => ({ ...prev, url: formattedUrl }));
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const img = new Image();
        img.src = reader.result;

        img.onload = () => {
          const canvas = document.createElement("canvas");
          const maxSize = 600;
          let width = img.width;
          let height = img.height;
          if (width > height) {
            if (width > maxSize) {
              height *= maxSize / width;
              width = maxSize;
            }
          } else {
            if (height > maxSize) {
              width *= maxSize / height;
              height = maxSize;
            }
          }
          canvas.width = width;
          canvas.height = height;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0, width, height);

          const resizedImage = canvas.toDataURL("image/jpeg", 0.7);
          setNewBlog({ ...newBlog, image: resizedImage });
          setPreviewImage(resizedImage);
        };
      };
      reader.readAsDataURL(file);
    }
  };

  const handleRemoveImage = () => {
    setNewBlog({ ...newBlog, image: null });
    setPreviewImage(null);
  };

  const handleSave = async () => {
    try {
      const payload = {
        name: newBlog.name,
        content: newBlog.content,
        status: newBlog.status,
        created_at: newBlog.created_at,
        url: newBlog.url,
        image: newBlog.image,
        comments_enabled: newBlog.comments_enabled,
      };

      if (newBlog.id) {
        await axiosInstance.put(`/blogs/${newBlog.id}`, payload);
      } else {
        await axiosInstance.post('/blogs', payload);
      }

      fetchBlogs();
      resetBlogForm();
      setShowToast(true);
      setTimeout(() => setShowToast(false), 3000);
      setIsModalOpen(false);
      setIsEditing(false);
    } catch (error) {
      console.error('Failed to save blog:', error);
    }
  };

  const handleEdit = (blog) => {
    setNewBlog(blog);
    setPreviewImage(blog.image);
    setIsEditing(true);
    setIsModalOpen(true);
  };

  const handleDelete = (id) => {
    setSelectedBlogId(id);
    setConfirmDeleteModalOpen(true);
  };

  const confirmDeleteBlog = async () => {
    try {
      await axiosInstance.delete(`/blogs/${selectedBlogId}`);
      fetchBlogs();
      setConfirmDeleteModalOpen(false);
      setShowToast(true);
    } catch (error) {
      console.error('Failed to delete blog:', error);
    }
  };

  return ( 
    <Box sx={{ fontFamily: 'Cinzel, serif' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
      <Box display="flex" alignItems="center" gap={2}>
      <FontAwesomeIcon icon={faEdit} style={{ color: '#a52a2a', fontSize: '2rem', fontWeight: 'bold' }} />
       <Typography variant="h4" sx={{ fontFamily: 'Cinzel, serif', fontWeight: 'bold' }}>
          Blog Content Editor
        </Typography>
        </Box>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            resetBlogForm();
            setIsModalOpen(true);
          }}
        >
          Add Blog
        </Button>
      </Box>

      <TableContainer component={Paper} mt={3}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>URL</TableCell>
              <TableCell>Thumbnail</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Allow Comments</TableCell>
              <TableCell>Comments</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {blogs.map((blog) => (
              <TableRow key={blog.id}>
                <TableCell>{blog.name}</TableCell>
                <TableCell>{new Date(blog.created_at).toLocaleDateString()}</TableCell>
                <TableCell>{blog.url}</TableCell>
                <TableCell>
                  {blog.image ? (
                    <img
                      src={blog.image}
                      alt="Blog Thumbnail"
                      style={{
                        width: '80px',
                        height: 'auto',
                        borderRadius: '4px',
                        objectFit: 'cover',
                      }}
                    />
                  ) : (
                    <Typography variant="caption" color="textSecondary">
                      No Image
                    </Typography>
                  )}
                </TableCell>
                <TableCell>
                  <Checkbox
                    checked={blog.status}
                    onChange={async (e) => {
                      const newStatus = e.target.checked;
                      try {
                        setBlogs((prevBlogs) =>
                          prevBlogs.map((b) =>
                            b.id === blog.id ? { ...b, status: newStatus } : b
                          )
                        );
                        await axiosInstance.put(`/blogs/${blog.id}`, {
                          ...blog,
                          status: newStatus,
                        });
                      } catch (error) {
                        console.error('Failed to update status:', error);
                        setBlogs((prevBlogs) =>
                          prevBlogs.map((b) =>
                            b.id === blog.id ? { ...b, status: !newStatus } : b
                          )
                        );
                      }
                    }}
                  />
                </TableCell>
                <TableCell>
                  <Checkbox
                    checked={blog.comments_enabled}
                    onChange={async (e) => {
                      const newCommentsEnabled = e.target.checked;
                      try {
                        setBlogs((prevBlogs) =>
                          prevBlogs.map((b) =>
                            b.id === blog.id
                              ? { ...b, comments_enabled: newCommentsEnabled }
                              : b
                          )
                        );
                        await axiosInstance.put(`/blogs/${blog.id}`, {
                          ...blog,
                          comments_enabled: newCommentsEnabled,
                        });
                      } catch (error) {
                        console.error('Failed to update comments_enabled:', error);
                        setBlogs((prevBlogs) =>
                          prevBlogs.map((b) =>
                            b.id === blog.id
                              ? { ...b, comments_enabled: !newCommentsEnabled }
                              : b
                          )
                        );
                      }
                    }}
                  />
                </TableCell>
                <TableCell>
                  {blog.comment_count > 0 && (
                    <IconButton onClick={() => fetchComments(blog.id)}>
                      <Comment />
                    </IconButton>
                  )}
                </TableCell>
                <TableCell>
                  <IconButton onClick={() => handleEdit(blog)} aria-label="edit">
                    <Edit color="primary" />
                  </IconButton>
                  <IconButton onClick={() => handleDelete(blog.id)} aria-label="delete">
                    <Delete color="error" />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* WriteBlog Add/Edit Modal */}
      <Dialog open={isModalOpen} onClose={() => setIsModalOpen(false)} fullWidth maxWidth="xl" disableEnforceFocus={true}
        sx={{
          '& .MuiDialog-paper': {
            width: '90%',
            height: '90vh',
            maxHeight: '100vh',
          },
        }}>
        <DialogTitle>{isEditing ? 'Edit Blog' : 'Add Blog'}</DialogTitle>
        <DialogContent>
          <TextField
            label="Blog Name"
            value={newBlog.name}
            onChange={(e) => setNewBlog({ ...newBlog, name: e.target.value })}
            onBlur={handleTitleBlur}
            fullWidth
            margin="normal"
          />
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, marginBottom: 2 }}>
          {/* URL Field */}
          <TextField
            label="URL"
            value={newBlog.url}
            onChange={(e) => setNewBlog({ ...newBlog, url: e.target.value })}
            fullWidth
            margin="normal"
            sx={{ flex: 5 }} // Half width
          />

          {/* Publish Date Field */}
          <TextField
            label="Publish Date"
            type="date"
            value={newBlog.created_at ? format(new Date(newBlog.created_at), 'yyyy-MM-dd') : ''}
            onChange={(e) => setNewBlog({ ...newBlog, created_at: e.target.value })}
            margin="normal"
            InputLabelProps={{ shrink: true }}
            sx={{ flex: 1 }} // Equal share of the remaining space
          />

          {/* Active Checkbox */}
          <FormControlLabel
            control={
              <Checkbox
                checked={newBlog.status}
                onChange={(e) => setNewBlog({ ...newBlog, status: e.target.checked })}
              />
            }
            label="Active"
            sx={{ flex: 1 }} // Equal share of the remaining space
          />

          {/* Allow Comments Checkbox */}
          <FormControlLabel
            control={
              <Checkbox
                checked={newBlog.comments_enabled}
                onChange={(e) => setNewBlog({ ...newBlog, comments_enabled: e.target.checked })}
              />
            }
            label="Allow Comments"
            sx={{ flex: 1 }} // Equal share of the remaining space
          />
        </Box>
        {/* WriteBlog Editor  */}
        <ContentEditor
            value={newBlog.content}
            onEditorChange={(content) => setNewBlog({ ...newBlog, content })}
          />
          <Typography variant="subtitle1" mt={2}>
            Blog Card Image
          </Typography>
          <Stack spacing={2}>
            <input type="file" onChange={handleImageChange} />
            {previewImage && (
              <Box position="relative" display="inline-block" maxWidth={250}>
                <IconButton
                  onClick={handleRemoveImage}
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    zIndex: 1,
                    backgroundColor: 'rgba(255, 255, 255, 0.8)',
                    '&:hover': { backgroundColor: 'rgba(255, 255, 255, 1)' },
                  }}
                  size="small"
                >
                  <CancelIcon fontSize="small" sx={{ color: 'red' }} />
                </IconButton>
                <img
                  src={previewImage}
                  alt="Preview"
                  style={{
                    width: '250px',
                    height: 'auto',
                    borderRadius: 4,
                  }}
                />
              </Box>
            )}
          </Stack>
        </DialogContent>
        <DialogActions>
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
          <Button onClick={() => setIsModalOpen(false)} color="secondary" variant="contained">
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary" variant="contained">
            {isEditing ? 'Update Blog' : 'Save Blog'}
          </Button>
          </Box>
        </DialogActions>
      </Dialog>

      <Dialog open={confirmDeleteModalOpen} onClose={() => setConfirmDeleteModalOpen(false)}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to delete this blog?</DialogContentText>
        </DialogContent>
        <DialogActions>
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
          <Button onClick={() => setConfirmDeleteModalOpen(false)} color="secondary" variant="contained">
            Cancel
          </Button>
          <Button onClick={confirmDeleteBlog} color="error" variant="contained">
            Delete
          </Button>
          </Box>
        </DialogActions>
      </Dialog>

      <Dialog
        open={commentsModalOpen}
        onClose={() => setCommentsModalOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Comments for Blog</DialogTitle>
        <DialogContent>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: '60%' }}>Comment</TableCell>
                <TableCell sx={{ width: '30%' }}>Date</TableCell>
                <TableCell sx={{ width: '10%' }}>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {selectedBlogComments.map((comment) => (
                <TableRow key={comment.id}>
                  <TableCell sx={{ width: '60%' }}>{comment.comment}</TableCell>
                  <TableCell sx={{ width: '30%' }}>
                    {new Date(comment.created_at).toLocaleString()}
                  </TableCell>
                  <TableCell sx={{ width: '10%' }}>
                    <IconButton onClick={() => handleDeleteComment(comment.id)}>
                      <Delete color="error" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setCommentsModalOpen(false)} variant="contained" color="primary"> 
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar open={showToast} autoHideDuration={3000} onClose={() => setShowToast(false)}>
        <Alert onClose={() => setShowToast(false)} severity="success">
          Action Successful!
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default AdminWriteBlog;
