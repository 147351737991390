// pages/About.js
import React, { useState, useEffect } from 'react';
import axiosInstance from '../api/axiosInstance';
import { Container, Box } from '@mui/material';
import TrackPageView from '../components/TrackPageView'; // Import TrackPageView

const About = ({ setContentLoaded }) => {
  const [content, setContent] = useState({ body: '' }); 

  useEffect(() => {
    const fetchContent = async () => {
      setContentLoaded(false); // Reset loading state at the start
      try {
        const response = await axiosInstance.get('/content/about');
        setContent({
          body: response.data.content || ''  // Accessing content field directly
        });
        setContentLoaded(true); // Indicate that content has loaded successfully
      } catch (error) {
        console.error('Failed to fetch content for About page:', error);
      }
    };

    fetchContent();
  }, [setContentLoaded]);

  return (
    <Container maxWidth="xl" sx={{ mt: 2, px: 2, fontFamily: 'Cinzel, serif', bgcolor: '#fff' }}>
     <TrackPageView pageName="About" />
      <Box
        component="main"
        dangerouslySetInnerHTML={{ __html: content.body }}
        sx={{
          fontSize: { xs: '1rem', sm: '1.1rem' },
          fontFamily: 'Cinzel, serif',
          lineHeight: 1.6,
          '& h1, & h2': {
            color: '#a52a2a',
            textAlign: 'center',
            fontSize: { xs: '1.5rem', sm: '2rem' },
            mt: 2,
          },
          '& p': {
            my: 2,
            textAlign: { xs: 'center', sm: 'left' },
          },
          '& ul': {
            paddingLeft: 3,
            '& li': {
              mb: 1,
              fontWeight: 'bold',
            },
          },
          '& img': {
            maxWidth: '100%',
            height: 'auto',
            display: 'block',
            margin: '20px auto',
          },
        }}
      />
    </Container>
  );
};

export default About;
