// components/ContactDrawer.js
import React, { useState } from 'react';
import { Fab, Drawer, Box, TextField, Button, Typography, Alert, Snackbar } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import axiosInstance from '../api/axiosInstance';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import TrackPageView from '../components/TrackPageView'; // Import TrackPageView
import logo from '../assets/logo.png';

const ContactDrawer = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [formData, setFormData] = useState({ name: '', email: '', message: '' });
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [showSnackbar, setShowSnackbar] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Detect if the screen is small

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
    setFormData({ name: '', email: '', message: '' }); // Reset form fields
    setError('');
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccessMessage('');

    if (!formData.name || !formData.email || !formData.message) {
      setError('All fields are required.');
      return;
    }

    try {
      await axiosInstance.post('/contact/send', formData);
      setSuccessMessage('Message sent successfully!');
      setShowSnackbar(true); // Show snackbar
      setIsOpen(false); // Close the drawer
    } catch (err) {
      setError('Failed to send message. Please try again later.');
    }
  };

  return (
    <>
      {/* Floating Chat Icon */}
      <Fab
      color="primary"
      onClick={toggleDrawer}
      sx={{
        position: 'fixed',
        bottom: isMobile ? 8 : 16, // Adjust position for mobile
        right: isMobile ? 8 : 16,  // Adjust position for mobile
        bgcolor: '#a52a2a',
        color: 'white',
        '&:hover': { bgcolor: '#8b0000' },
        width: isMobile ? 35 : 45, // Smaller size for mobile
        height: isMobile ? 35 : 45, // Smaller size for mobile
      }}
    >
      <EmailIcon sx={{ fontSize: isMobile ? 18 : 26 }} /> {/* Smaller icon for mobile */}
    </Fab>

 {/* Drawer */}
<Drawer anchor="right" open={isOpen} onClose={toggleDrawer}>
 <TrackPageView pageName="Contact" />
  <Box
    sx={{
      width: 300,
      p: 2,
      display: 'flex',
      flexDirection: 'column',
      gap: 2,
    }}
  >
    {/* Logo and Header */}
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Box
        component="img"
        src={logo}
        alt="Scribe & Scroll Logo"
        sx={{
          height: isMobile ? 55 : 65, // Adjust the height
          width: 'auto', // Maintain aspect ratio
          maxWidth: '100%', // Prevent it from exceeding the container width
          objectFit: 'contain', // Ensure it fits nicely
        }}
      />
      <Typography
        sx={{
          textAlign: 'center', // Center-align the text
          mt: 2, // Add spacing between the logo and the text
          fontSize: isMobile ? '0.9rem' : '1rem', // Adjust font size for mobile
          fontFamily: 'Cinzel, serif',
          fontWeight: '500',
        }}
      >
        We would be blessed to hear from you! Please leave us a message below.
      </Typography>
    </Box>

    {/* Error Alert */}
    {error && <Alert severity="error">{error}</Alert>}

    {/* Form */}
    <form onSubmit={handleSubmit}>
      <TextField
        sx={{
          fontFamily: 'Cinzel, serif',
          fontWeight: 'bold',
        }}      
        label="Name"
        name="name"
        value={formData.name}
        onChange={handleChange}
        fullWidth
        required
        margin="normal"

      />
      <TextField
        sx={{
          fontFamily: 'Cinzel, serif',
          fontWeight: 'bold',
        }}      
        label="Email"
        name="email"
        type="email"
        value={formData.email}
        onChange={handleChange}
        fullWidth
        required
        margin="normal"
      />
      <TextField
        sx={{
          fontFamily: 'Cinzel, serif',
          fontWeight: 'bold',
        }}      
        label="Message"
        name="message"
        value={formData.message}
        onChange={handleChange}
        fullWidth
        required
        multiline
        rows={4}
        margin="normal"
      />
      {/* Send Button */}
      <Button
        type="submit"
        variant="contained"
        fullWidth
        sx={{
          mt: 2,
          bgcolor: '#a52a2a',
          fontFamily: 'Cinzel, serif',
          fontWeight: 'bold',
          '&:hover': {
            bgcolor: '#8b0000', // Darker shade for hover effect
          },
          color: '#fff', // Ensure text is readable
          transition: 'background-color 0.3s ease', // Smooth hover transition
        }}
      >
        Send Message
      </Button>

      {/* Close Button for Mobile */}
        <Button
          variant="outlined"
          fullWidth
          onClick={toggleDrawer}
          sx={{
            mt: 2,
            color: '#a52a2a',
            borderColor: '#a52a2a',
            fontFamily: 'Cinzel, serif',
            fontWeight: 'bold',
            '&:hover': {
              borderColor: '#8b0000',
              color: '#8b0000',
              bgcolor: 'rgba(139, 0, 0, 0.1)', // Subtle background hover
            },
            transition: 'all 0.3s ease', // Smooth hover transition
          }}
        >
          Close
        </Button>
    </form>
  </Box>
</Drawer>

      {/* Snackbar */}
      <Snackbar
        open={showSnackbar}
        autoHideDuration={3000}
        onClose={() => setShowSnackbar(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={() => setShowSnackbar(false)} severity="success" sx={{ width: '100%' }}>
          {successMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ContactDrawer;
