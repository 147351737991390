import React, { useState } from 'react';
import { Container, Box, TextField, Button, Typography, Grid, Link, Snackbar, Alert } from '@mui/material';
import axiosInstance from '../api/axiosInstance';
import { useParams, useNavigate } from 'react-router-dom';
import logo from '../assets/logo.png';
import TrackPageView from '../components/TrackPageView'; 

const ResetPassword = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const [password, setPassword] = useState('');
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastSeverity, setToastSeverity] = useState('success');
  const [passwordError, setPasswordError] = useState('');

  const validatePassword = (password) => {
    const minLength = 7;
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\W).+$/; // At least one lowercase, one uppercase, and one special character
    if (password.length < minLength) {
      return `Password must be at least ${minLength} characters.`;
    }
    if (!regex.test(password)) {
      return 'Password must include uppercase, lowercase, and special characters.';
    }
    return '';
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate password
    const validationError = validatePassword(password);
    if (validationError) {
      setPasswordError(validationError);
      return;
    }

    try {
      await axiosInstance.post('/auth/reset-password', { token, password });
      setToastMessage('Password reset successful. Redirecting to login...');
      setShowToast(true);
      setToastSeverity('success');
      setPasswordError('');
      setTimeout(() => navigate('/login'), 2000);
    } catch (error) {
      setToastMessage('Error resetting password, request a new link and try again.');
      setShowToast(true);
      setToastSeverity('error');
    }
  };

  return (
    <Container maxWidth="xs" sx={{ mt: 4, fontFamily: 'Cinzel, serif' }}>
      <TrackPageView pageName="Reset Password" />
      <Box
        sx={{
          p: 3,
          mt: 3,
          border: '1px solid #ddd',
          borderRadius: 2,
          boxShadow: 6,
          textAlign: 'center',
        }}
      >
        <Box
          component="img"
          src={logo}
          alt="Scribe & Scroll Logo"
          sx={{ height: { xs: 40, sm: 50, md: 55 }, mt: { xs: 1, sm: 1, md: 1 }, ml: { xs: 1, sm: 1, md: 1 }, mb: 2 }}
        />
        <Typography variant="h5" gutterBottom sx={{ color: '#3d3d3d', fontWeight: 'bold', fontFamily: 'Cinzel, serif', mb: 2 }}>
          Reset Password
        </Typography>
        <Typography variant="p" gutterBottom sx={{ color: '#3d3d3d', fontFamily: 'Cinzel, serif', mb: 2 }}>
          Enter your new password below.
        </Typography>
        <Box component="form" onSubmit={handleSubmit} sx={{ width: '100%' }}>
          <TextField
            label="New Password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            fullWidth
            required
            sx={{ mb: 2, mt: 2 }}
            error={Boolean(passwordError)}
            helperText={passwordError}
          />
          <Button type="submit" fullWidth variant="contained" sx={{ mt: 2, bgcolor: '#a52a2a', '&:hover': { bgcolor: '#8b0000' } }}>
            Reset Password
          </Button>
        </Box>
        <Grid container sx={{ mt: 2 }}>
          <Grid item xs>
            <Link href="/login" variant="body2" sx={{ textAlign: 'center', display: 'block' }}>
              Return to Login page
            </Link>
          </Grid>
        </Grid>
      </Box>
      <Snackbar
        open={showToast}
        autoHideDuration={3000}
        onClose={() => setShowToast(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={() => setShowToast(false)} severity={toastSeverity} sx={{ width: '100%' }}>
          {toastMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default ResetPassword;