//pages/AdminSiteContentEditor.js
import React, { useState, useEffect } from 'react';
import axiosInstance from '../api/axiosInstance';
import ContentEditor from '../components/ContentEditor';
import { FormControlLabel, FormControl, MenuItem, Select, Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, Paper, Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Snackbar, Alert, Checkbox } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { styled } from '@mui/material/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookBible } from '@fortawesome/free-solid-svg-icons';

const HoverableIconButton = styled(IconButton)(({ theme }) => ({
  '&:hover': {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.contrastText,
  },
}));

const AnimatedTableRow = styled(TableRow)(({ theme }) => ({
  transition: 'background-color 0.3s ease, transform 0.3s ease',
  '&.moved': {
    backgroundColor: theme.palette.action.hover,
    transform: 'scale(0.72)',
  },
}));

const AdminSiteContentEditor = () => {
  const [pages, setPages] = useState([]);
  const [selectedPage, setSelectedPage] = useState(null);
  const [content, setContent] = useState('');
  const [active, setActive] = useState(false);
  const [newPageName, setNewPageName] = useState('');
  const [parentId, setParentId] = useState(null);
  const [newPath, setNewPath] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastSeverity, setToastSeverity] = useState('success');
  const [movedRowIndex, setMovedRowIndex] = useState(null);

  const fetchPages = async () => {
    try {
      const response = await axiosInstance.get('/content');
      const sortedPages = response.data.sort((a, b) => a.menu_order - b.menu_order); // Sort by menu_order
      setPages(sortedPages);
    } catch (error) {
      console.error('Failed to fetch pages:', error);
    }
  };
  

  useEffect(() => {
    fetchPages();
  }, []);


  const handleMoveUp = (index) => {
    if (index > 0) {
      const updatedPages = [...pages];
      [updatedPages[index - 1], updatedPages[index]] = [updatedPages[index], updatedPages[index - 1]];

      const tempOrder = updatedPages[index].menu_order;
      updatedPages[index].menu_order = updatedPages[index - 1].menu_order;
      updatedPages[index - 1].menu_order = tempOrder;

      setPages(updatedPages);
      setMovedRowIndex(index - 1); // Highlight the moved row
      setTimeout(() => setMovedRowIndex(null), 500); // Remove highlight after 500ms
      saveOrderToDatabase(updatedPages);
    }
  };

  const handleMoveDown = (index) => {
    if (index < pages.length - 1) {
      const updatedPages = [...pages];
      [updatedPages[index], updatedPages[index + 1]] = [updatedPages[index + 1], updatedPages[index]];

      const tempOrder = updatedPages[index].menu_order;
      updatedPages[index].menu_order = updatedPages[index + 1].menu_order;
      updatedPages[index + 1].menu_order = tempOrder;

      setPages(updatedPages);
      setMovedRowIndex(index + 1); // Highlight the moved row
      setTimeout(() => setMovedRowIndex(null), 500); // Remove highlight after 500ms
      saveOrderToDatabase(updatedPages);
    }
  };
  
  const saveOrderToDatabase = async (updatedPages) => {
    try {
      const payload = updatedPages.map((page) => ({
        id: page.id,
        menu_order: page.menu_order, // Ensure we send only id and menu_order
      }));
  
      await axiosInstance.put('/menuorder/order', { pages: payload });
  
      setToastMessage('Order updated successfully!');
      setToastSeverity('success');
      setShowToast(true);
    } catch (error) {
      console.error('Failed to save menu order:', error);
      setToastMessage('Failed to save menu order. Please try again.');
      setToastSeverity('error');
      setShowToast(true);
    }
  };
  

  const handleEdit = async (page) => {
    setSelectedPage(page);
    setNewPageName(page.page);
    setNewPath(page.path || '');
    setParentId(page.parent_id || null); // Set parent_id
    setActive(page.active|| null); // Set parent_id
    await fetchContent(page.page);
    setIsEditMode(true);
    setIsModalOpen(true);
  };

  const handleAdd = () => {
    setSelectedPage(null);
    setNewPageName('');
    setNewPath('/'); // Set default value for path
    setParentId(null); // Default to no parent
    setActive(null); // Set parent_id
    setContent('');
    setIsEditMode(false);
    setIsModalOpen(true);
  };

  const fetchContent = async (page) => {
    try {
      const response = await axiosInstance.get(`/content/${page}`);
      setContent(response.data.content || '');
    } catch (error) {
      console.error(`Failed to fetch content for ${page}:`, error);
    }
  };

  const handleDelete = async () => {
    if (!selectedPage) return;

    try {
      await axiosInstance.delete(`/content/${selectedPage.page}`);
      setPages((prevPages) => prevPages.filter((p) => p.id !== selectedPage.id));
      setToastMessage('Page deleted successfully!');
      setToastSeverity('success');
      setShowToast(true);
    } catch (error) {
      console.error('Failed to delete page:', error);
      setToastMessage('Failed to delete page. Please try again.');
      setToastSeverity('error');
      setShowToast(true);
    } finally {
      setIsDeleteModalOpen(false);
      setSelectedPage(null);
    }
  };

  const handleSave = async () => {
    if (!newPageName.trim() || !newPath.trim()) {
      setToastMessage('Page name and path are required!');
      setToastSeverity('error');
      setShowToast(true);
      return;
    }
  
    try {
      if (isEditMode) {
        await axiosInstance.put(`/content/${selectedPage.page}`, {
          page: newPageName,
          path: newPath,
          content,
          active,
          parent_id: parentId,

        });
        setToastMessage('Page updated successfully!');
        setToastSeverity('success');
        setShowToast(true);
      } else {
        const newMenuOrder = pages.length > 0 ? Math.max(...pages.map((p) => p.menu_order)) + 1 : 0;
        await axiosInstance.post('/content', {
          page: newPageName,
          path: newPath,
          content,
          active,
          menu_order: newMenuOrder, // Assign new menu_order
          parent_id: parentId,
        });
        setToastMessage('Page added successfully!');
        setToastSeverity('success');
        setShowToast(true);
      }
      fetchPages();
      setIsModalOpen(false);
    } catch (error) {
      console.error('Failed to save page:', error);
      setToastMessage('Failed to save page. Please try again.');
      setToastSeverity('error');
      setShowToast(true);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setIsDeleteModalOpen(false);
    setSelectedPage(null);
    setNewPageName('');
    setContent('');
    setParentId(null);
  };

  const formatDate = (date) => {
    return date ? new Date(date).toLocaleString('en-US', { dateStyle: 'short', timeStyle: 'short' }) : 'N/A';
  };

  const handleTitleBlur = () => {
    if (newPageName) {
      const formattedPath = '/' + newPageName
        .toLowerCase()
        .replace(/\s+/g, '-') // Replace spaces with hyphens
        .replace(/[^a-z0-9-]/g, ''); // Remove invalid characters

      setNewPath(formattedPath);
    }
  };

  return ( 
    <Box sx={{ fontFamily: 'Cinzel, serif' }}>
      <Box 
        sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center', // Ensures both the heading and button align vertically
          mb: 2 
        }}
      >
        <Box display="flex" alignItems="center" gap={2}>
        <FontAwesomeIcon icon={faBookBible} style={{ color: '#a52a2a', fontSize: '2rem', fontWeight: 'bold' }} />
        <Typography 
          variant="h4" 
          sx={{ fontFamily: 'Cinzel, serif', margin: 0, fontWeight: 'bold' }} // Remove unnecessary spacing
          gutterBottom={false} // Prevent extra spacing at the bottom of the heading
        >
          Website Content Editor 
        </Typography>
        </Box>
        <Button
          variant="contained"
          color="primary"
          onClick={handleAdd}
          sx={{
            fontSize: '0.875rem', // Adjust font size for a normal button appearance
            padding: '6px 16px', // Default padding for a regular Material-UI button
            lineHeight: 1.75, // Standard line height for consistency
          }}
        >
          Add Page
        </Button>
      </Box>

      <TableContainer component={Paper} sx={{ mb: 3 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell><strong>Page Name</strong></TableCell>
              <TableCell><strong>Path</strong></TableCell>
              <TableCell><strong>Parent Page</strong></TableCell>
              <TableCell><strong>Last Updated</strong></TableCell>
              <TableCell>In Menu?</TableCell>
              <TableCell align="center"><strong>Order</strong></TableCell>
              <TableCell align="center"><strong>Actions</strong></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {pages.map((page, index) => (
              <AnimatedTableRow key={page.id || `temp-${index}`} className={movedRowIndex === index ? 'moved' : ''}>
                <TableCell>{page.page ? page.page.charAt(0).toUpperCase() + page.page.slice(1) : 'Unknown Page'}</TableCell>
                <TableCell>{page.path || 'N/A'}</TableCell>
                 <TableCell>{page.parent_id ? pages.find((p) => p.id === page.parent_id)?.page || 'Unknown' : 'None'}</TableCell>
                <TableCell>{formatDate(page.updated_at)}</TableCell>
                <TableCell>
                  <Checkbox
                    checked={page.active}
                    onChange={async (e) => {
                      const newActiveStatus = e.target.checked;

                      try {
                        setPages((prevPages) =>
                          prevPages.map((p) =>
                            p.id === page.id ? { ...p, active: newActiveStatus } : p
                          )
                        );

                        await axiosInstance.put(`/content/${page.page}`, {
                          ...page,
                          active: newActiveStatus,
                        });
                      } catch (error) {
                        console.error('Failed to update active status:', error);

                        // Revert UI change on error
                        setPages((prevPages) =>
                          prevPages.map((p) =>
                            p.id === page.id ? { ...p, active: !newActiveStatus } : p
                          )
                        );
                      }
                    }}
                    inputProps={{ 'aria-label': 'Active Status' }}
                  />
                </TableCell>

                <TableCell>
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <HoverableIconButton onClick={() => handleMoveUp(index)} disabled={index === 0}>
                      <ArrowUpwardIcon />
                    </HoverableIconButton>
                    <HoverableIconButton onClick={() => handleMoveDown(index)} disabled={index === pages.length - 1}>
                      <ArrowDownwardIcon />
                    </HoverableIconButton>
                  </Box>
                </TableCell>
                <TableCell align="center">
                  <IconButton onClick={() => page.page && handleEdit(page)} color="primary">
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      setSelectedPage(page);
                      setIsDeleteModalOpen(true);
                    }}
                    color="error"
                    disabled={!page.page}
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </AnimatedTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* SiteContentEditor Add/Edit Modal */}
      <Dialog open={isModalOpen} onClose={handleCloseModal} fullWidth maxWidth="xl" disableEnforceFocus={true}
       sx={{
        '& .MuiDialog-paper': {
          width: '90%',
          height: '90vh',
          maxHeight: '100vh',
        },
      }}>
        <DialogTitle>{isEditMode ? 'Edit Page' : 'Add Page'}</DialogTitle>
        <DialogContent dividers>
          <TextField
            fullWidth
            label="Page Name"
            value={newPageName}
            onChange={(e) => setNewPageName(e.target.value)}
            margin="normal"
            onBlur={handleTitleBlur}
          />
            <Box
              sx={{
                display: 'flex',
                gap: 2, // Space between the two fields
                alignItems: 'center',
                marginBottom: 2, // Adds space below the row
              }}
            >
              {/* Path Field */}
              <TextField
                sx={{ flex: 4 }} // Takes 60% of the space
                label="Path"
                value={newPath}
                onChange={(e) => setNewPath(e.target.value)}
                margin="normal"
              />

              {/* Parent Selector */}
              <FormControl sx={{ flex: 3 }} margin="normal">
                <Select
                  value={parentId}
                  onChange={(e) => setParentId(e.target.value)}
                  displayEmpty
                  renderValue={(selected) => {
                    if (selected === null) {
                      return <em>No Parent</em>; // Display "No Parent" when no value is selected
                    }
                    const selectedPage = pages.find((page) => page.id === selected);
                    return selectedPage ? selectedPage.page : ''; // Show the selected parent name
                  }}
                >
                  <MenuItem value={null}>
                    <em>No Parent</em>
                  </MenuItem>
                  {pages
                    .filter((page) => page.id !== (selectedPage?.id || null)) // Exclude the current page
                    .filter((page) => page.parent_id === null) // Exclude pages that are already children
                    .map((page) => (
                      <MenuItem key={page.id} value={page.id}>
                        {page.page}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            <FormControlLabel sx={{ flex: 1 }} margin="normal"
              control={
                <Checkbox
                  checked={active || false}
                  onChange={(e) => setActive(e.target.checked)}
                />
              }
              label="In Menu?"
            />
            </Box>

          {/* WebsiteContent Editor  */}
          <ContentEditor
            value={content}
            onEditorChange={(newContent) => setContent(newContent)}
          />
        </DialogContent>
        <DialogActions>
          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
            <Button onClick={handleCloseModal} color="secondary" variant="contained">
              Cancel
            </Button>
            <Button onClick={handleSave} color="primary" variant="contained">
              {isEditMode ? 'Save Changes' : 'Add Page'}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>

      <Dialog open={isDeleteModalOpen} onClose={handleCloseModal} fullWidth maxWidth="xs">
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          Are you sure you want to delete the page <strong>{selectedPage?.page}</strong>?
        </DialogContent>
        <DialogActions>
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
          <Button onClick={handleCloseModal} color="secondary" variant="contained">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="error" variant="contained">
            Delete
          </Button>
          </Box>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={showToast}
        autoHideDuration={3000}
        onClose={() => setShowToast(false)}
      >
        <Alert onClose={() => setShowToast(false)} severity={toastSeverity} sx={{ width: '100%' }}>
          {toastMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default AdminSiteContentEditor;
