// components/Dashboard.js
import React from 'react';
import { Box, Typography, Paper } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserShield } from '@fortawesome/free-solid-svg-icons';

const Dashboard = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',  // Stack text vertically
        justifyContent: 'flex-start', // Align items to the top
        alignItems: 'center',     // Center horizontally
        height: '100vh',          // Make sure it takes full height of the viewport
        textAlign: 'center',      // Center text horizontally
        paddingTop: '20px',       // Add some space at the top
      }}
    >
      {/* Admin Icon and Title */}
      <Paper elevation={8} sx={{ p: 10 }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <FontAwesomeIcon icon={faUserShield} style={{ color: '#a52a2a', fontSize: '2rem', fontWeight: 'bold', marginRight: '10px' }} />
       <Typography variant="h3" sx={{ color: '#000', fontFamily: 'Cinzel, serif' }}>
          Scribe <span style={{ color: '#a52a2a' }}>&</span> Scroll Admin Dashboard
        </Typography>
      </Box>
      <Typography variant="h5" sx={{ color: '#000', mt: 2, fontFamily: 'Cinzel, serif', marginLeft: '38px' }}>
        Please select a menu item from the list on the left to get started.
      </Typography>
    </Paper>
    </Box>

  );
};

export default Dashboard;
