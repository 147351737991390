// pages/FreeEbook.js
import React, { useState, useEffect } from 'react';
import { Container, Typography, Box, TextField, Button, Snackbar, Alert  } from '@mui/material';
import bookCover from '../assets/bookcover.png';
import ebookFile from '../assets/Its_in_What_Youre_Eating.pdf';
import axiosInstance from '../api/axiosInstance';
import TrackPageView from '../components/TrackPageView'; // Import TrackPageView

const FreeEbook = ({ setContentLoaded }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [subscribed, setSubscribed] = useState(false);
  const [content, setContent] = useState('');
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastSeverity, setToastSeverity] = useState('success');


  useEffect(() => {
    const fetchContent = async () => {
      setContentLoaded(false);
      try {
        const response = await axiosInstance.get('/content/freeebook');
        setContent(response.data.content || '');
        setContentLoaded(true);
      } catch (error) {
        console.error('Failed to fetch content for FreeEbook page:', error);
      }
    };

    fetchContent();

    const hasSubscribed = localStorage.getItem('hasSubscribed');
    if (hasSubscribed) {
      setSubscribed(true);
    }
  }, [setContentLoaded]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await axiosInstance.post('/newsletter', { name, email });
      setSubscribed(true);
      localStorage.setItem('hasSubscribed', 'true');
      setToastMessage('Subscribed successfully!');
      setShowToast(true);
      setToastSeverity('success');
      setTimeout(() => setShowToast(false), 3000);
    } catch (error) {
      if (error.response && error.response.status === 400) {
        // Handle the "already subscribed" error
        const message = error.response.data?.message || 'Already subscribed';
        if (message.includes('already subscribed')) {
          setSubscribed(true);
          localStorage.setItem('hasSubscribed', 'true');
          setToastMessage("Thank you, you've already subscribed!");
          setShowToast(true);
          setToastSeverity('error');
          setTimeout(() => setShowToast(false), 3000);
        }
      } else {
        setToastMessage('Something went wrong. Please try again.');
        setShowToast(true);
        setToastSeverity('error');
        setTimeout(() => setShowToast(false), 3000);
      }
    }
  };



  return (
    <Container maxWidth="xl" sx={{ mt: 2, px: 2, bgcolor: '#fff' }}>
     <TrackPageView pageName="FreeeBook" />
      <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} alignItems="start" gap={4}>
        
        {/* Content Section - Now on Left Side */}
        <Box flex={{ xs: '100%', md: '60%' }} dangerouslySetInnerHTML={{ __html: content }} sx={{
          fontFamily: 'Cinzel, serif',
          '& h1': {
            fontSize: { xs: '1.5em', md: '2.5em' },
            mb: 1,
            textAlign: 'center',
          },
          '& p': {
            fontSize: { xs: '1em', md: '1.1em' },
            lineHeight: 1.5,
            textAlign: { xs: 'center', md: 'left' },
          },
          '& ul': {
            listStyleType: 'disc',
            paddingLeft: { xs: 2, md: 4 },
          },
        }} />

        {/* Download Form Section - Now on Right Side */}
        <Box flex={{ xs: '100%', md: '35%', boxShadow: 3 }} sx={{
          fontFamily: 'Cinzel, serif',
          bgcolor: '#cfcfcf',
          p: 3,
          borderRadius: 2,
          boxShadow: 3,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
          mt: { xs: 2, md: 0 }, // Even spacing on top
        }}>
          <Box component="img" src={bookCover} alt="Book Cover" sx={{ width: '100%', maxWidth: 300, mb: 2 }} />
          {!subscribed ? (
            <>
              <Typography variant="h5" sx={{ fontFamily: 'Cinzel, serif', fontSize: { xs: '1.0em', md: '1.3em' }, mb: 2 }}>
                Subscribe to our newsletter for a free PDF download!
              </Typography>
              <Box component="form" onSubmit={handleSubmit} sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 2 }}>
                <TextField
                  type="text"
                  placeholder="Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                  sx={{ bgcolor: '#fff', borderRadius: 1 }}
                />
                <TextField
                  type="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  sx={{ bgcolor: '#fff', borderRadius: 1 }}
                />
                <Button type="submit" variant="contained" sx={{ bgcolor: '#a52a2a', '&:hover': { bgcolor: '#8b0000' } }}>
                  SUBSCRIBE!!
                </Button>
              </Box>
            </>
          ) : (
            <>
              <Typography sx={{ mb: 2, fontWeight: 'bold', fontFamily: 'Cinzel, serif' }}>Already signed up, here's your free book!</Typography>
              <Button
                href={ebookFile}
                download="Its_in_What_Youre_Eating.pdf"
                variant="contained"
                sx={{
                  bgcolor: '#a52a2a',
                  '&:hover': { bgcolor: '#8b0000' },
                  textDecoration: 'none',
                  color: '#fff',
                  fontWeight: 'bold',
                  fontFamily: 'Cinzel, serif'
                }}
              >
                Download FREE E-Book Here
              </Button>
            </>
          )}
        </Box>
      </Box>
      <Snackbar
            open={showToast}
            autoHideDuration={3000}
            onClose={() => setShowToast(false)}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }} // Adjust position here
            >
            <Alert onClose={() => setShowToast(false)} severity={toastSeverity} sx={{ width: '100%' }}>
                {toastMessage}
            </Alert>
            </Snackbar>
    </Container>
  );
};

export default FreeEbook;
