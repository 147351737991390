// Pages/ForgotPassword.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../api/axiosInstance';
import { Container, Typography, Box, TextField, Button, Snackbar, Alert } from '@mui/material';
import logo from '../assets/logo.png';
import TrackPageView from '../components/TrackPageView'; // Import TrackPageView

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastSeverity, setToastSeverity] = useState('success');

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axiosInstance.post('/auth/forgot-password', { email });
      setToastMessage('If the email exists, you will receive a reset link shortly.');
      setShowToast(true);
      setToastSeverity('success'); 
      setEmail('');
    } catch (error) {
      setToastMessage('If the email exists, you will receive a reset link shortly.');
      setShowToast(true);
      setToastSeverity('success'); 
    }
  };

  return (
    <Container maxWidth="xs" sx={{ mt: 4, fontFamily: 'Cinzel, serif' }}>
       <TrackPageView pageName="Forgot Password" />
      <Box
        sx={{
          p: 3,
          mt: 3,
          border: '1px solid #ddd',
          borderRadius: 2,
          boxShadow: 6,
          textAlign: 'center',
        }}
      >
        <Box
          component="img"
          src={logo}
          alt="Scribe & Scroll Logo"
          sx={{ height: { xs: 40, sm: 50, md: 55 }, mt: { xs: 1, sm: 1, md: 1 }, ml: { xs: 1, sm: 1, md: 1 }, mb: 2 }}
        />
        <Typography variant="h5" gutterBottom sx={{ color: '#3d3d3d', fontWeight: 'bold', fontFamily: 'Cinzel, serif', mb: 2 }}>
          Forgot Password
        </Typography>
        <Typography variant="p" gutterBottom sx={{ color: '#3d3d3d', fontFamily: 'Cinzel, serif' }}>
          Enter your email address below to receive a password reset link.
        </Typography>

        <Box component="form" onSubmit={handleSubmit} sx={{ display: 'flex', flexDirection: 'column' }}>
          <TextField
            label="Email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            fullWidth
            margin="normal"
          />
          <Button
            type="submit"
            variant="contained"
            fullWidth
            sx={{ mt: 2, bgcolor: '#a52a2a', '&:hover': { bgcolor: '#8b0000' } }}
          >
            Send Reset Link
          </Button>
        </Box>

        <Typography
          variant="body2"
          onClick={() => navigate('/login')}
          sx={{
            mt: 2,
            color: '#007BFF',
            cursor: 'pointer',
            textDecoration: 'underline',
          }}
        >
          Return to Login page
        </Typography>
      </Box>
      <Snackbar
            open={showToast}
            autoHideDuration={3000}
            onClose={() => setShowToast(false)}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }} // Adjust position here
            >
            <Alert onClose={() => setShowToast(false)} severity={toastSeverity} sx={{ width: '100%' }}>
                {toastMessage}
            </Alert>
            </Snackbar>
    </Container>
  );
};

export default ForgotPassword;
