// components/layout.js
import React from 'react';
import Box from '@mui/material/Box';
import Header from './Header';
import SimpleHeader from './SimpleHeader';
import Footer from './Footer';

const Layout = ({ isLoggedIn, children, contentLoaded }) => {
  const showFooter = !isLoggedIn && contentLoaded; // prevent footer from loading until content is loaded

  return (
    <Box display="flex" flexDirection="column" minHeight="100vh">
      {/* Header */}
      {isLoggedIn ? <SimpleHeader isLoggedIn={isLoggedIn} /> : <Header isLoggedIn={isLoggedIn} />}

      {/* Main Content */}
      <Box component="main" flex="1" display="flex" flexDirection="column" width="100%">
        {children}
      </Box>

      {/* Footer */}
      {showFooter && <Footer />}
    </Box>
  );
};

export default Layout;
