// components/footer.js
import React from 'react';
import { Container, Box, IconButton, Typography, Link } from '@mui/material';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';
import NewsletterSignup from './NewsletterSignup';

const Footer = () => {
  return (
    <Box
      component="footer"
      sx={{
        mt: 2,
        bgcolor: '#f4f4f4',
        py: 2,
        textAlign: 'center',
        boxShadow: '0px -2px 8px rgba(0, 0, 0, 0.2)',
        position: 'relative',
      }}
    >
      <Container maxWidth="md">
        <NewsletterSignup />

        {/* Footer Content */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: { xs: 2, md: 0 },
            mt: 1,
          }}
        >
          {/* Social Media Icons */}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              gap: 2,
              order: { xs: 0, md: 1 }, // Social icons appear first on mobile, second on desktop
            }}
          >
            <IconButton
              href="https://twitter.com/ScribenScroll"
              target="_blank"
              rel="noopener noreferrer"
              sx={{ color: '#1DA1F2', fontSize: { xs: '1.2em', md: '1.9m' }, p: 0.5 }}
            >
              <TwitterIcon />
            </IconButton>
            <IconButton
              href="https://www.instagram.com/lowenburns/"
              target="_blank"
              rel="noopener noreferrer"
              sx={{ color: '#C13584', fontSize: { xs: '1.2em', md: '1.9em' }, p: 0.5 }}
            >
              <InstagramIcon />
            </IconButton>
            <IconButton
              href="https://www.facebook.com/profile.php?id=100092659627389"
              target="_blank"
              rel="noopener noreferrer"
              sx={{ color: '#1877F2', fontSize: { xs: '1.2em', md: '1.9em' }, p: 0.5 }}
            >
              <FacebookIcon/>
            </IconButton>
            <IconButton
              href="https://www.youtube.com/channel/UC0ceMU-_ykrXS56FDg1j9gg"
              target="_blank"
              rel="noopener noreferrer"
              sx={{ color: '#FF0000', fontSize: { xs: '1.2em', md: '1.9em' }, p: 0.5 }}
            >
              <YouTubeIcon />
            </IconButton>
          </Box>

          {/* Copyright Text */}
          <Typography
            sx={{
              fontSize: { xs: '0.75em', md: '0.9em' },
              color: '#555',
              textAlign: { xs: 'center', md: 'left' },
              fontWeight: 'bold',
              fontFamily: 'Cinzel, serif',
              order: { xs: 1, md: 0 }, // Copyright text appears second on mobile, first on desktop
            }}
          >
            © {new Date().getFullYear()} Scribe & Scroll Writing
          </Typography>

          {/* Privacy Policy and Terms */}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              gap: 2,
              fontWeight: 'bold',
              fontFamily: 'Cinzel, serif',
              order: { xs: 2, md: 2 }, // Privacy links appear last on both mobile and desktop
            }}
          >
            <Link
              href="/privacy-terms#privacy"
              underline="hover"
              sx={{
                fontSize: { xs: '0.7em', md: '0.9em' },
                color: '#555',
                '&:hover': { color: '#a52a2a' },
              }}
            >
              Privacy Policy
            </Link>
            <Link
              href="/privacy-terms#terms"
              underline="hover"
              sx={{
                fontSize: { xs: '0.7em', md: '0.9em' },
                color: '#555',
                '&:hover': { color: '#a52a2a' },
              }}
            >
              Terms & Conditions
            </Link>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
