//Pages/PrivacyTerms.js
import React, { useState, useEffect } from 'react';
import { Box, Tabs, Tab, Typography, Container } from '@mui/material';
import { useLocation } from 'react-router-dom';
import TrackPageView from '../components/TrackPageView';
import Footer from '../components/Footer';

const PrivacyTerms = ({ setContentLoaded }) => {
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(0);

  // Update active tab based on URL hash
  useEffect(() => {
    if (location.hash === '#privacy') {
      setActiveTab(0);
    } else if (location.hash === '#terms') {
      setActiveTab(1);
    }
  }, [location, setContentLoaded]);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <>
      <Container maxWidth="xl" sx={{ mt: 2, px: 2, fontFamily: 'Cinzel, serif', bgcolor: '#fff' }}>
        <TrackPageView pageName="Privacy Policy or Terms and Conditions" />
        <Box sx={{ p: 3 }}>
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            centered
            TabIndicatorProps={{ style: { backgroundColor: '#a52a2a' } }}
            sx={{
              minHeight: { xs: '32px', sm: '48px' }, // Smaller height for mobile
            }}
          >
            <Tab
              sx={{
                fontWeight: 'bold',
                fontFamily: 'Cinzel, serif',
                fontSize: { xs: '0.7rem', sm: '1rem' }, // Smaller font size for mobile
                minWidth: { xs: '100px', sm: '100px' }, // Adjust tab width for mobile
                minHeight: { xs: '32px', sm: '48px' }, // Adjust tab height for mobile
                color: activeTab === 0 ? '#a52a2a' : 'inherit',
                '&.Mui-selected': {
                  color: '#a52a2a',
                },
              }}
              label="Privacy Policy"
            />
            <Tab
              sx={{
                fontWeight: 'bold',
                fontFamily: 'Cinzel, serif',
                fontSize: { xs: '0.7rem', sm: '1rem' }, // Smaller font size for mobile
                minWidth: { xs: '80px', sm: '100px' }, // Adjust tab width for mobile
                minHeight: { xs: '32px', sm: '48px' }, // Adjust tab height for mobile
                color: activeTab === 1 ? '#a52a2a' : 'inherit',
                '&.Mui-selected': {
                  color: '#a52a2a',
                },
              }}
              label="Terms & Conditions"
            />
          </Tabs>

        <Box sx={{ mt: 3, fontSize: { xs: '0.7rem', sm: '1rem' }, fontFamily: 'Cinzel, serif' }}>

        {activeTab === 0 && (
          <Typography sx={{ mt: 3, fontSize: { xs: '0.7rem', sm: '1rem' }, fontFamily: 'Cinzel, serif' }}>
            {/* Privacy Policy Content */}
            <h2>Privacy Policy</h2>
              <p>Your privacy is important to us at Scribe & Scroll Writing. This policy outlines how we collect, use, and protect your information:</p>
              <h3>Information We Collect</h3>
              <ul>
                <li><strong>Newsletter Subscriptions:</strong> When you sign up for our newsletter, we collect your name and email address to deliver updates and content.</li>
                <li><strong>Contact Form Submissions:</strong> When you contact us, we collect the name, email address, and message you provide.</li>
              </ul>
              <h3>How We Use Your Information</h3>
              <ul>
                <li><strong>Newsletter:</strong> Users who opt into the newsletter consent to receiving updates and content via email. You may unsubscribe at any time by emailing <a href="mailto:scribeandscrollwriting@gmail.com">scribeandscrollwriting@gmail.com</a>.</li>
                <li><strong>Contact Requests:</strong> Information submitted through our contact form will only be used to address your inquiry and will not be used for any other purpose.</li>
              </ul>
              <h3>Data Sharing</h3>
              <p>We do not sell, trade, or otherwise share your personal information with third parties. Your data is securely stored and only used as outlined in this policy.</p>
              <h3>Your Rights</h3>
              <p>You have the right to access, correct, or delete your personal information. If you wish to unsubscribe from our newsletter or request your data, please contact us at <a href="mailto:scribeandscrollwriting@gmail.com">scribeandscrollwriting@gmail.com</a>.</p>
              <h3>Global Compliance</h3>
              <p>This policy complies with global privacy standards, including GDPR and CCPA, ensuring your data is handled securely and transparently.</p>
              <h3>Updates to this Policy</h3>
              <p>We may update this Privacy Policy as needed. Please check this page periodically for changes.</p>
          </Typography>
        )}
        {activeTab === 1 && (
          <Typography sx={{ mt: 3, fontSize: { xs: '0.7rem', sm: '1rem' }, fontFamily: 'Cinzel, serif' }}>
            {/* Terms & Conditions Content */}
            <h2>Terms and Conditions</h2>
            <p>Welcome to Scribe & Scroll Writing. By accessing or using our website, you agree to the following terms:</p>
            <h3>1. Acceptance of Terms</h3>
            <p>By using this website, you agree to be bound by these terms. If you do not agree, please discontinue use of our website.</p>
            <h3>2. Content Ownership</h3>
            <p>All content on this website, including text, images, and logos, is owned by Scribe & Scroll Writing unless otherwise stated. Unauthorized reproduction or distribution is prohibited.</p>
            <h3>3. User Submissions</h3>
            <ul>
              <li><strong>Newsletter:</strong> By signing up for our newsletter, you agree to receive updates and content via email.</li>
              <li><strong>Contact Forms:</strong> Submitting a contact request authorizes us to use the information provided to respond to your inquiry.</li>
            </ul>
            <h3>4. Disclaimer</h3>
            <p>We strive to provide accurate and up-to-date content. However, we make no guarantees regarding the accuracy or completeness of the information provided on this site.</p>
            <h3>5. Limitation of Liability</h3>
            <p>To the fullest extent permitted by law, Scribe & Scroll Writing shall not be liable for any damages arising from the use of this website or its content.</p>
            <h3>6. Governing Law</h3>
            <p>These terms are governed by the laws of the country in which we operate. Users are responsible for compliance with their local laws.</p>
            <h3>7. Updates to Terms</h3>
            <p>We may update these Terms and Conditions as needed. Continued use of our site signifies acceptance of any changes.</p>
            <h3>Contact</h3>
            <p>If you have questions about our Privacy Policy or Terms and Conditions, please contact us at <a href="mailto:scribeandscrollwriting@gmail.com">scribeandscrollwriting@gmail.com</a>.</p>
          </Typography>
        )}
       </Box>
     </Box>
    </Container>
    <Footer />
    </>
  );
};

export default PrivacyTerms;
