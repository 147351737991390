//Pages/AdminAnalytics.js
import React, { useEffect, useState } from 'react';
import axiosInstance from '../api/axiosInstance';
import { CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Chart as ChartJS } from 'chart.js';
import { Line } from 'react-chartjs-2';
import { Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Grid } from '@mui/material';
import { format, parseISO } from 'date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartLine } from '@fortawesome/free-solid-svg-icons';
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const AdminAnalytics = () => {
  const [trendData, setTrendData] = useState([]);
  const [topPages, setTopPages] = useState([]);
  const [totalViews, setTotalViews] = useState(0);

  useEffect(() => {
    const fetchAnalytics = async () => {
      try {
        const trendResponse = await axiosInstance.get('/analytics/views/trend');
        const total = trendResponse.data.reduce((sum, data) => sum + parseInt(data.view_count, 10), 0);
        setTotalViews(total);

        const aggregatedTrendData = trendResponse.data.reduce((acc, current) => {
          const date = format(parseISO(current.view_date), 'yyyy-MM-dd');
          if (!acc[date]) acc[date] = 0;
          acc[date] += current.view_count;
          return acc;
        }, {});

        setTrendData(
          Object.entries(aggregatedTrendData).map(([date, count]) => ({
            date,
            view_count: count,
          }))
        );

        const topPagesResponse = await axiosInstance.get('/analytics/views/top-pages');
        setTopPages(topPagesResponse.data);
      } catch (error) {
        console.error('Failed to load analytics data:', error);
      }
    };

    fetchAnalytics();
  }, []);

  const trendChartData = {
    labels: trendData.map(data => data.date),
    datasets: [
      {
        data: trendData.map(data => data.view_count),
        borderColor: '#a52a2a',
        tension: 0.1,
      },
    ],
  };
  
  const chartOptions = {
    plugins: {
      legend: {
        display: false,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
  };

  return ( 
    <Box sx={{ fontFamily: 'Cinzel, serif' }}>
      <Box display="flex" alignItems="center" gap={2} mb={2} >
      <FontAwesomeIcon icon={faChartLine} style={{ color: '#a52a2a', fontSize: '2rem', fontWeight: 'bold' }} />
      <Typography variant="h4" sx={{ fontFamily: 'Cinzel, serif', fontWeight: 'bold' }}>
        Site Analytics
      </Typography>
      </Box>
      <Grid container spacing={2} alignItems="stretch" sx={{ mb: 4 }}>
        <Grid item xs={12} md={10}>
          <Typography variant="h6" sx={{ fontFamily: 'Cinzel, serif', fontSize: { xs: '1rem', md: '1.25rem', fontWeight: 'bold' } }} gutterBottom>
            Page Views Over Time
          </Typography>
          <Box
            sx={{
              p: 2,
              bgcolor: '#f9f9f9',
              borderRadius: 2,
              boxShadow: 1,
              height: { xs: 150, sm: 200, md: 200 },
              maxHeight: 300,
            }}
          >
            <Line data={trendChartData} options={chartOptions} />
          </Box>
        </Grid>

        <Grid item xs={12} md={2}>
          <Typography variant="h6" gutterBottom sx={{ fontFamily: 'Cinzel, serif', fontWeight: 'bold', fontSize: { xs: '1rem', md: '1.25rem' } }}>
            Total Views
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              bgcolor: '#f9f9f9',
              borderRadius: 2,
              boxShadow: 1,
              height: { xs: 150, sm: 200, md: 230 },
            }}
          >
            <Typography variant="h4" sx={{ color: '#a52a2a', fontWeight: 'bold', textAlign: 'center', fontSize: { xs: '1.5rem', md: '2rem' } }}>
              {totalViews}
            </Typography>
            <Typography>pages viewed</Typography>
          </Box>
        </Grid>
      </Grid>

      <Box>
        <Typography sx={{ fontFamily: 'Cinzel, serif', fontWeight: 'bold', fontSize: { xs: '1rem', md: '1.25rem' } }} variant="h6" gutterBottom>
          Top Pages Viewed by Users
        </Typography>
        <TableContainer component={Paper} sx={{ mt: 2, overflowY: 'auto' }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow sx={{ bgcolor: '#e0e0e0', height: '30px' }}>
                <TableCell sx={{ fontWeight: 'bold' }}>Page Name</TableCell>
                <TableCell align="right" sx={{ fontWeight: 'bold' }}>Views</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {topPages.map((page) => (
                <TableRow key={page.page_name} sx={{ height: '30px' }}>
                  <TableCell sx={{ py: 1 }}>{page.page_name}</TableCell>
                  <TableCell align="right" sx={{ py: 1 }}>{page.view_count}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default AdminAnalytics;
