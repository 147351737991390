//Pages/AdminNewsletterEmails.js
import React, { useEffect, useState } from 'react';
import axiosInstance from '../api/axiosInstance';
import { Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Snackbar, Alert } from '@mui/material';
import { Delete, Download } from '@mui/icons-material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

const AdminNewsletterEmails = () => {
  const [subscribers, setSubscribers] = useState([]);
  const [showToast, setShowToast] = useState(false);

  useEffect(() => {
    const fetchSubscribers = async () => {
      try {
        const response = await axiosInstance.get('/newsletter');
        setSubscribers(response.data);
      } catch (error) {
        console.error('Failed to fetch subscribers:', error);
      }
    };
    fetchSubscribers();
  }, []);

  const deleteSubscriber = async (id) => {
    try {
      await axiosInstance.delete(`/newsletter/${id}`);
      setSubscribers((prevSubscribers) => prevSubscribers.filter((subscriber) => subscriber.id !== id));
      setShowToast(true);
    } catch (error) {
      console.error('Failed to delete subscriber:', error);
    }
  };

  const exportToCSV = () => {
    const csvHeaders = ['Name', 'Email'];
    const csvRows = subscribers.map((subscriber) => [subscriber.name, subscriber.email, subscriber.signed_up_at]);
    const csvContent =
      'data:text/csv;charset=utf-8,' +
      [csvHeaders.join(','), ...csvRows.map((row) => row.join(','))].join('\n');

    const link = document.createElement('a');
    link.href = encodeURI(csvContent);
    link.download = 'newsletter_subscribers.csv';
    link.click();
  };

  return ( 
    <Box sx={{ fontFamily: 'Cinzel, serif' }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
      <Box display="flex" alignItems="center" gap={2}>
      <FontAwesomeIcon icon={faEnvelope} style={{ color: '#a52a2a', fontSize: '2rem', fontWeight: 'bold' }} />
        <Typography sx={{ fontFamily: 'Cinzel, serif', fontWeight: 'bold' }} variant="h4">Newsletter Subscribers</Typography>
        </Box>
        <IconButton onClick={exportToCSV} title="Export to CSV" color="primary">
          <Download />
        </IconButton>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Signup Date</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {subscribers.map((subscriber) => (
              <TableRow key={subscriber.id}>
                <TableCell>{subscriber.name}</TableCell>
                <TableCell>{subscriber.email}</TableCell>
                <TableCell>{new Date(subscriber.signed_up_at).toLocaleDateString()}</TableCell>
                <TableCell align="right">
                  <IconButton
                    onClick={() => deleteSubscriber(subscriber.id)}
                    color="error"
                    title="Delete Subscriber"
                  >
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Snackbar
        open={showToast}
        autoHideDuration={3000}
        onClose={() => setShowToast(false)}
      >
        <Alert onClose={() => setShowToast(false)} severity="success">
          Subscriber deleted successfully!
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default AdminNewsletterEmails;
