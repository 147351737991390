//Pages/BlogPage.js
import React, { useEffect, useState } from 'react';
import axiosInstance from '../api/axiosInstance';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, Typography, Box, Button, TextField, Card } from '@mui/material';
import TrackPageView from '../components/TrackPageView';

const BlogPage = ({ setContentLoaded }) => {
  const { url } = useParams();
  const [blog, setBlog] = useState(null);
  const [liked, setLiked] = useState(localStorage.getItem(`liked_${url}`) === 'true');
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchBlogContent = async () => {
      setContentLoaded(false);
      try {
        const response = await axiosInstance.get(`/blogs/${url}`);
        setBlog(response.data);
        setContentLoaded(true);
      } catch (error) {
        console.error('Failed to fetch blog content:', error);
      }
    };
    fetchBlogContent();
  }, [url, setContentLoaded]);

  useEffect(() => {
    if (blog) {
      const fetchComments = async () => {
        try {
          const response = await axiosInstance.get(`/blogs/${blog.id}/comments`);
          setComments(response.data);
        } catch (error) {
          console.error('Failed to fetch comments:', error);
        }
      };
      fetchComments();
    }
  }, [blog]);

  const handleLike = async () => {
    try {
      if (!liked) {
        await axiosInstance.post(`/blogs/${blog.id}/like`);
        setLiked(true);
        localStorage.setItem(`liked_${url}`, 'true');
      } else {
        localStorage.removeItem(`liked_${url}`);
        setLiked(false);
      }
    } catch (error) {
      console.error('Failed to like/unlike the blog:', error);
    }
  };

  const handleAddComment = async () => {
    try {
      await axiosInstance.post(`/blogs/${blog.id}/comment`, { comment: newComment });
      setComments([{ comment: newComment, created_at: new Date().toISOString() }, ...comments]);
      setNewComment('');
    } catch (error) {
      console.error('Failed to add comment:', error);
    }
  };

  const handleBackClick = () => {
    navigate('/engage');
  };

  return (
    <Container maxWidth="xl" sx={{ mt: 2, px: 2, fontFamily: 'Cinzel, serif', bgcolor: '#fff' }}>
      {blog && <TrackPageView pageName={blog.name} />}
      {blog ? (
        <>
          {/* Navigation and Like Buttons */}
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
            <Button
              variant="outlined"
              onClick={handleBackClick}
              sx={{
                fontFamily: 'Cinzel, serif',
                fontWeight: 'bold',
                color: '#a52a2a',
                borderColor: '#a52a2a',
                fontSize: '0.9em',
                py: 0.5,
                '&:hover': {
                  color: '#fff',
                  borderColor: '#a52a2a',
                  backgroundColor: '#a52a2a',
                },
              }}
            >
              ← Back to Blogs
            </Button>
            <Button
              onClick={handleLike}
              sx={{
                color: liked ? '#a52a2a' : '#a52a2a',
                '&:hover': { color: '#fff', borderColor: '#a52a2a', backgroundColor: '#a52a2a' },
                fontFamily: 'Cinzel, serif',
              }}
            >
              {liked ? 'Liked!' : 'Like'} ❤️
            </Button>
          </Box>

          {/* Blog Details Banner */}
          <Card sx={{ mb: 2, p: 2, bgcolor: 'rgba(200, 200, 200, 0.3)', fontFamily: 'Cinzel, serif' }}>
            <Box
              display="flex"
              flexDirection={{ xs: 'column', sm: 'row' }}
              justifyContent="space-between"
              alignItems="center"
              sx={{ textAlign: { xs: 'center', sm: 'left' } }}
            >
              <Typography
                variant="body2"
                sx={{
                  fontSize: { xs: '0.9em', sm: '1em' },
                  color: '#333',
                }}
              >
                <b>Author:</b> Teri Lowen Burns
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  fontSize: { xs: '0.9em', sm: '1em' },
                  color: '#333',
                }}
              >
                <b>Published:</b> {new Date(blog.created_at).toLocaleDateString()}
              </Typography>
            </Box>
          </Card>

          {/* Blog Title and Content */}
          <Typography
            variant="h4"
            gutterBottom
            sx={{
              fontFamily: 'Cinzel, serif',
              textAlign: { xs: 'left', sm: 'center' },
            }}
          >
            {blog.name}
          </Typography>
          <Box
              dangerouslySetInnerHTML={{ __html: blog.content }}
              sx={{
                lineHeight: 1.6,
                fontFamily: 'Cinzel, serif',
                textAlign: { xs: 'left', sm: 'center' },
                '& img': {
                  maxWidth: '100%',
                  height: 'auto',
                },
                '& iframe': {
                  width: '100%',
                  height: 'auto',
                  aspectRatio: '16/9', // Maintain 16:9 aspect ratio
                },
                '& .responsive-video': {
                  position: 'relative',
                  paddingBottom: '56.25%', // 16:9 aspect ratio
                  height: 0,
                  overflow: 'hidden',
                  '& iframe': {
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                  },
                },
              }}
            />

          {/* Comment Section */}
          {blog.comments_enabled && (
            <Box sx={{ mt: 4 }}>
              <Typography
                variant="h5"
                sx={{
                  color: '#a52a2a',
                  mb: 2,
                  fontFamily: 'Cinzel, serif',
                  textAlign: { xs: 'left', sm: 'left' },
                }}
              >
                Comments
              </Typography>
              <TextField
                fullWidth
                multiline
                minRows={3}
                placeholder="Add a comment..."
                value={newComment}
                onChange={(e) => setNewComment(e.target.value)}
                sx={{
                  mb: 2,
                  fontFamily: 'Cinzel, serif',
                  '& .MuiOutlinedInput-root': {
                    borderRadius: 2,
                  },
                }}
              />
              <Button
                onClick={handleAddComment}
                variant="contained"
                sx={{
                  bgcolor: '#a52a2a',
                  '&:hover': { bgcolor: '#8b0000' },
                  fontFamily: 'Cinzel, serif',
                }}
              >
                Add Comment
              </Button>

              {/* Display Comments */}
              <Box sx={{ mt: 3 }}>
                {comments.map((comment, index) => (
                  <Card key={index} sx={{ mb: 2, p: 2, bgcolor: '#f9f9f9', boxShadow: 1, fontFamily: 'Cinzel, serif' }}>
                    <Typography variant="body2">{comment.comment}</Typography>
                    <Typography variant="caption" sx={{ mt: 1, display: 'block', color: '#777' }}>
                      {new Date(comment.created_at).toLocaleString()}
                    </Typography>
                  </Card>
                ))}
              </Box>
            </Box>
          )}
        </>
      ) : (
        <Box>
          <Button
            variant="outlined"
            onClick={handleBackClick}
            sx={{
              color: '#a52a2a',
              borderColor: '#a52a2a',
              fontSize: '0.9em',
              py: 0.5,
              '&:hover': {
                color: '#fff',
                borderColor: '#a52a2a',
                backgroundColor: '#a52a2a',
              },
            }}
          >
            ← Back to Blogs
          </Button>
          <Typography variant="body1" sx={{ mt: 2, fontFamily: 'Cinzel, serif' }}>
            Well, that’s strange! The blog here can’t be found! We will pray about it; in the meantime, click the button above.
          </Typography>
        </Box>
      )}
    </Container>
  );
};

export default BlogPage;
