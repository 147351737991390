// components/TrackPageView.js
import { useEffect, useRef } from 'react';
import axiosInstance from '../api/axiosInstance';

const TrackPageView = ({ pageName }) => {
  const isTracked = useRef(false);

  useEffect(() => {
    const trackPageView = async () => {
      try {
        await axiosInstance.post('/analytics/track', { page_name: pageName });
        isTracked.current = true; // Mark as tracked to prevent duplicate tracking
      } catch (error) {
        console.error(`Failed to track page view for ${pageName}:`, error);
      }
    };

    if (!isTracked.current) {
      trackPageView();
    }
  }, [pageName]);

  return null;
};

export default TrackPageView;
