// components/NavMenu.js
import React, { useState, useEffect } from 'react';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Box, Typography, List, ListItem, ListItemButton, ListItemIcon, Tooltip, Collapse, IconButton, Drawer, useMediaQuery, AppBar, Toolbar } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faAddressBook, faEnvelope, faEdit, faBookBible, faChevronLeft, faChevronRight, faBars, faChartLine, faBullhorn, faPenNib } from '@fortawesome/free-solid-svg-icons';
import { useTheme } from '@mui/material/styles';
import Dashboard from './Dashboard';
import axiosInstance from '../api/axiosInstance';

// Reusable NavMenuItem Component
const NavMenuItem = ({ to, icon, label, isExpanded, tooltipTitle }) => {
  const location = useLocation();
  const isActive = location.pathname === to;

  return (
    <ListItem disablePadding>
      <Tooltip title={tooltipTitle || label} placement="right" arrow disableHoverListener={isExpanded}>
        <ListItemButton
          component={Link}
          to={to}
          selected={isActive}
          sx={{
            mb: 0.25,
            width: 210,
            color: isActive ? '#a52a2a' : '#000',
            bgcolor: isActive ? '#ffffff !important' : 'transparent',
            fontFamily: 'Cinzel, serif',
            borderRight: isActive ? '3px solid #a52a2a' : '3px solid transparent',
            boxShadow: isActive ? '3' : '',
            '&:hover': { bgcolor: '#ffffff', color: '#a52a2a', boxShadow: 3, borderRight: '3px solid #a52a2a' },
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: '30px',
              color: isActive ? '#a52a2a' : '#000',
            }}
          >
            {icon}
          </ListItemIcon>
          <Collapse in={isExpanded} timeout="auto" orientation="horizontal">
            <Typography
              sx={{
                pr: 0,
                color: isActive ? '#a52a2a' : '#000',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                fontWeight: isActive ? 'medium' : 'normal',
              }}
            >
              {label}
            </Typography>
          </Collapse>
        </ListItemButton>
      </Tooltip>
    </ListItem>
  );
};

// Main NavMenu Component
const NavMenu = () => {
  const [isExpanded, setIsExpanded] = useState(true);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [userRole, setUserRole] = useState('');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    fetchUserRole();
  }, []);

  const fetchUserRole = async () => {
    try {
      const response = await axiosInstance.get('/users/me');
      setUserRole(response.data.user_role); // Fetch and set the user's role
    } catch (error) {
      console.error('Error fetching user role:', error);
    }
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  // Add new menu items here
  const menuItems = [
    { to: '/dashboard/adminsitecontenteditor', label: 'Website Content', icon: <FontAwesomeIcon icon={faBookBible}/> },
    { to: '/dashboard/adminwriteblog', label: 'Blog Content', icon: <FontAwesomeIcon icon={faEdit} /> },
    { to: '/dashboard/adminannouncements', label: 'Announcements', icon: <FontAwesomeIcon icon={faBullhorn} /> },
    { to: '/dashboard/adminnewslettercontent', label: 'Newsletter Content', icon: <FontAwesomeIcon icon={faPenNib}/> },
    { to: '/dashboard/adminnewsletteremails', label: 'Newsletter Emails', icon: <FontAwesomeIcon icon={faEnvelope} /> },
    { to: '/dashboard/admincustomercontact', label: 'Customer Contacts', icon: <FontAwesomeIcon icon={faAddressBook}  /> },
    { to: '/dashboard/adminanalytics', label: 'Site Analytics', icon: <FontAwesomeIcon icon={faChartLine}/> },
    { to: '/dashboard/admininterface', label: 'Admin Tools', icon: <FontAwesomeIcon icon={faCog}/> },
  ];

  // Filter menu items based on user role
  const filteredMenuItems = menuItems.filter((item) => {
    // Only allow 'Admin Tools' for admin users
    if (item.to === '/dashboard/admininterface') {
      return userRole === 'Admin';
    }
    return true; // Keep other items for all users
  });

  const menuContent = (
    <Box
      sx={{
        width: isExpanded ? 210 : 50,
        transition: 'width 0.3s',
        bgcolor: '#efefee',
        color: '#000',
        overflow: 'hidden',
        borderRight: '1px solid #D3D3D3',
      }}
    >
      {/* Collapsible Header */}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: isExpanded ? 'space-between' : 'center',
          p: 1,
        }}
      >
        {isExpanded && (
          <Typography variant="h6" sx={{ ml: 1, color: '#000', fontFamily: 'Cinzel, serif', fontWeight: 'bold', overflow: 'hidden', whiteSpace: 'nowrap', cursor: 'pointer' }}  onClick={() => navigate('/dashboard')}>
            Admin Menu
          </Typography>
        )}
      <IconButton
        onClick={() => setIsExpanded(!isExpanded)}
        sx={{
          color: '#a52a2a', 
          fontSize: '1rem',
          '&:hover': {
            color: '#000', // Changes color to black on hover
             bgcolor: 'white',
          },
        }}
      >
        <FontAwesomeIcon icon={isExpanded ? faChevronLeft : faChevronRight} />
      </IconButton>
      </Box>

      {/* Menu Items */}
      <List>
        {filteredMenuItems.map((item) => (
          <NavMenuItem
            key={item.to}
            to={item.to}
            label={item.label}
            icon={item.icon}
            isExpanded={isExpanded}
          />
        ))}
      </List>
    </Box>
  );

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh', fontFamily: 'Cinzel, serif' }}>
      {/* AppBar for Mobile */}
      {isMobile && (
        <AppBar position="static" sx={{ bgcolor: '#efefee', color: '#a52a2a', boxShadow: 'none' }}>
          <Toolbar>
            <IconButton edge="start" onClick={handleDrawerToggle} sx={{ mr: 2 }}>
              <FontAwesomeIcon icon={faBars} />
            </IconButton>
            <Typography variant="h6" noWrap>
              Dashboard
            </Typography>
          </Toolbar>
        </AppBar>
      )}

      {/* Sidebar and Main Content */}
      <Box sx={{ display: 'flex', flex: 1, mt: isMobile ? 0 : 0 }}>
        {isMobile ? (
          <Drawer
            anchor="left"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{ keepMounted: true }}
          >
            {menuContent}
          </Drawer>
        ) : (
          menuContent
        )}

        <Box
          component="main"
          sx={{
            flex: 1,
            p: 4,
            bgcolor: '#fdfdfd',
            fontFamily: 'Cinzel, serif',
          }}
        >
          {location.pathname === '/dashboard' ? <Dashboard /> : <Outlet />}
        </Box>
      </Box>
    </Box>
  );
};

export default NavMenu;
