//Pages/AdminAnnouncements.js
import React, { useState, useEffect } from 'react';
import { Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Switch, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { Edit, Delete } from '@mui/icons-material';
import axiosInstance from '../api/axiosInstance';
import ContentEditor from '../components/ContentEditor';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBullhorn } from '@fortawesome/free-solid-svg-icons';

const AdminAnnouncements = () => {
  const [announcements, setAnnouncements] = useState([]);
  const [currentAnnouncement, setCurrentAnnouncement] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [announcementToDelete, setAnnouncementToDelete] = useState(null);

  const fetchAnnouncements = async () => {
    try {
      const response = await axiosInstance.get('/announcements');
      setAnnouncements(response.data);
    } catch (error) {
      console.error('Failed to fetch announcements:', error);
    }
  };

  const handleToggleActive = async (id, isActive) => {
    try {
      if (!isActive) {
        await axiosInstance.put('/announcements/deactivate-all');
        await axiosInstance.put(`/announcements/${id}`, { is_active: true });
        setAnnouncements((prev) =>
          prev.map((announcement) =>
            announcement.id === id
              ? { ...announcement, is_active: true }
              : { ...announcement, is_active: false }
          )
        );
      } else {
        await axiosInstance.put(`/announcements/${id}`, { is_active: false });
        setAnnouncements((prev) =>
          prev.map((announcement) =>
            announcement.id === id
              ? { ...announcement, is_active: false }
              : announcement
          )
        );
      }
    } catch (error) {
      console.error('Failed to update announcement active state:', error);
    }
  };

  const handleOpenModal = (announcement = null) => {
    setCurrentAnnouncement(
      announcement || {
        title: '',
        message: '',
        is_active: false,
      }
    );
    setIsEditing(!!announcement);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setCurrentAnnouncement(null);
    setIsEditing(false);
  };

  const handleSaveAnnouncement = async () => {
    try {
      if (isEditing) {
        await axiosInstance.put(`/announcements/${currentAnnouncement.id}`, currentAnnouncement);
      } else {
        const response = await axiosInstance.post('/announcements', currentAnnouncement);
        setAnnouncements((prev) => [...prev, response.data]);
      }
      fetchAnnouncements();
      handleCloseModal();
    } catch (error) {
      console.error('Failed to save announcement:', error);
    }
  };

  const handleOpenDeleteDialog = (announcement) => {
    setAnnouncementToDelete(announcement);
    setIsDeleteDialogOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setIsDeleteDialogOpen(false);
    setAnnouncementToDelete(null);
  };

  const handleConfirmDelete = async () => {
    try {
      await axiosInstance.delete(`/announcements/${announcementToDelete.id}`);
      setAnnouncements((prev) =>
        prev.filter((announcement) => announcement.id !== announcementToDelete.id)
      );
      handleCloseDeleteDialog();
    } catch (error) {
      console.error('Failed to delete announcement:', error);
    }
  };

  useEffect(() => {
    fetchAnnouncements();
  }, []);

  return ( 
    <Box sx={{ fontFamily: 'Cinzel, serif' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2, fontFamily: 'Cinzel, serif' }}>
      <Box display="flex" alignItems="center" gap={2}>
      <FontAwesomeIcon icon={faBullhorn} style={{ color: '#a52a2a', fontSize: '2rem', fontWeight: 'bold' }} />
        <Typography variant="h4" sx={{ fontFamily: 'Cinzel, serif', fontWeight: 'bold' }}>
          Announcements  Editor
        </Typography>
        </Box>
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleOpenModal()}
        >
          Add Announcement
        </Button>
      </Box>

      {/* Announcements Table */}
      <TableContainer component={Paper}>
  <Table sx={{ fontFamily: 'Cinzel, serif' }}>
    <TableHead>
      <TableRow>
        <TableCell sx={{ width: '30%' }}>Title</TableCell> {/* Allocates more room for Title */}
        <TableCell sx={{ width: '40%' }}>Message</TableCell> {/* Allocates more room for Message */}
        <TableCell sx={{ width: '10%', textAlign: 'right' }}>Date Created</TableCell> {/* Minimal space for Date */}
        <TableCell sx={{ width: '7%', textAlign: 'center' }}>Active</TableCell> {/* Minimal space for Active */}
        <TableCell sx={{ width: '13%', textAlign: 'center' }}>Actions</TableCell> {/* Minimal space for Actions */}
      </TableRow>
    </TableHead>
    <TableBody>
      {announcements.map((announcement) => (
        <TableRow key={announcement.id}>
          <TableCell sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {announcement.title}
          </TableCell>
          <TableCell>
            <Box
              sx={{
                maxWidth: '100%',
                maxHeight: 150,
                overflow: 'auto',
              }}
              dangerouslySetInnerHTML={{
                __html: announcement.message.replace(
                  /<img[^>]*src="data:image\/[^;]+;base64[^"]*"[^>]*>/g,
                  '<p><i>Image not shown due to size ...</i></p>'
                ),
              }}
            />
          </TableCell>
          <TableCell sx={{ textAlign: 'right' }}>
            {new Date(announcement.created_at).toLocaleDateString()}
          </TableCell>
          <TableCell align="center">
            <Switch
              checked={announcement.is_active}
              onChange={() => handleToggleActive(announcement.id, announcement.is_active)}
              color="primary"
            />
          </TableCell>
          <TableCell align="center">
            <IconButton onClick={() => handleOpenModal(announcement)}>
              <Edit color="primary" />
            </IconButton>
            <IconButton onClick={() => handleOpenDeleteDialog(announcement)}>
              <Delete color="error" />
            </IconButton>
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
</TableContainer>


      {/* Adminannouncement Add/Edit Modal */}
      <Dialog open={isModalOpen} onClose={handleCloseModal} fullWidth maxWidth="xl" disableEnforceFocus={true}
       sx={{
        '& .MuiDialog-paper': {
          width: '90%',
          height: '90vh',
          maxHeight: '100vh',
        },
      }}>
        <DialogTitle>{isEditing ? 'Edit Announcement' : 'Add Announcement'}</DialogTitle>
        <DialogContent>
          <Box sx={{ mb: 2 }}>
            <TextField
              label="Title"
              value={currentAnnouncement?.title || ''}
              onChange={(e) =>
                setCurrentAnnouncement({
                  ...currentAnnouncement,
                  title: e.target.value,
                })
              }
              fullWidth
              margin="normal"
            />
          </Box>
          {/* AdminAnnouncement Editor  */}
          <ContentEditor
            value={currentAnnouncement?.message || ''}
            onEditorChange={(content) =>
              setCurrentAnnouncement({
                ...currentAnnouncement,
                message: content,
              })
            }
          />
        </DialogContent>
        <DialogActions>
          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
            <Button onClick={handleCloseModal} color="secondary" variant="contained">
              Cancel
            </Button>
            <Button onClick={handleSaveAnnouncement} color="primary" variant="contained">
              Save
            </Button>
          </Box>
        </DialogActions>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog open={isDeleteDialogOpen} onClose={handleCloseDeleteDialog}>
        <DialogTitle>Delete Announcement</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this announcement?</Typography>
        </DialogContent>
        <DialogActions>
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
          <Button onClick={handleCloseDeleteDialog} color="secondary" variant="contained">
            Cancel
          </Button>
          <Button onClick={handleConfirmDelete} color="error" variant="contained">
            Delete
          </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default AdminAnnouncements;
