//pages/adminmanageusers.js
 import React, { useState, useEffect } from 'react';
import { Box, Typography, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, Modal, TextField, Select, MenuItem } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import axiosInstance from '../api/axiosInstance';

const AdminManageUsers = ({ setShowToast }) => {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await axiosInstance.get('/users');
      setUsers(response.data);
      //console.log("/users data",response.data);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const openModal = (user) => {
    setSelectedUser(user);
    setNewPassword('');
    setConfirmPassword('');
    setError('');
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setSelectedUser(null);
    setNewPassword('');
    setConfirmPassword('');
    setError('');
  };

  const handleChangePassword = async () => {
    if (newPassword !== confirmPassword) {
      setError('Passwords do not match.');
      return;
    }

    try {
      await axiosInstance.put(`/users/${selectedUser.id}/password`, { password: newPassword });
      setShowToast(true);
      closeModal();
    } catch (error) {
      console.error('Error updating password:', error);
      setError('Failed to update password. Please try again.');
    }
  };

  const handleRoleChange = async (userId, role) => {
    try {
      await axiosInstance.put(`/users/${userId}/role`, { role });
      setUsers((prev) =>
        prev.map((user) => (user.id === userId ? { ...user, user_role: role } : user))
      );
      setShowToast(true); // Assuming this shows a success toast
    } catch (error) {
      console.error('Error updating role:', error);
    }
  };

  return (
    <Box sx={{ fontFamily: 'Cinzel, serif' }}>
      <Box display="flex" alignItems="center" gap={2} mb={2}>
        <FontAwesomeIcon icon={faUser} style={{ color: '#a52a2a', fontSize: '1.5rem', fontWeight: 'bold' }} />
        <Typography variant="h5" sx={{ fontWeight: 'bold', fontFamily: 'Cinzel, serif' }}>
          Manage Users
        </Typography>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Username</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Date Created</TableCell>
              <TableCell>User Role</TableCell>
              <TableCell sx={{ textAlign: 'right' }}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user) => (
              <TableRow key={user.id}>
                <TableCell>{user.username}</TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>{new Date(user.created_at).toLocaleDateString()}</TableCell>
                <TableCell>
                <Select
                    value={user.user_role}
                    onChange={(e) => handleRoleChange(user.id, e.target.value)}
                    variant="outlined"
                    style={{ minWidth: '150px' }} // Adjust the width as needed
                >
                    <MenuItem value="User">User</MenuItem>
                    <MenuItem value="Admin">Admin</MenuItem>
                </Select>
                </TableCell>
                <TableCell sx={{ textAlign: 'right' }}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => openModal(user)}
                    sx={{ fontFamily: 'Cinzel, serif', bgcolor: '#a52a2a', '&:hover': { bgcolor: '#8b0000' } }}
                  >
                    Change Password
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Modal open={showModal} onClose={closeModal}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 300,
            bgcolor: 'background.paper',
            borderRadius: 2,
            p: 4,
            minWidth: '650px'
          }}
        >
          <Typography sx={{fontFamily: 'Cinzel, serif', fontWeight: 'bold'}} variant="h6">Change Password for {selectedUser?.username}</Typography>
          <Typography sx={{fontFamily: 'Cinzel, serif', minWidth: '650px'}} variant="p">Password must 7 characters, include uppercase, lowercase, and special characters.</Typography>

          {error && <Typography color="error">{error}</Typography>}
          <TextField
            label="New Password"
            type="password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            fullWidth
            sx={{ mt: 3 }}
          />
          <TextField
            label="Confirm Password"
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            fullWidth
            sx={{ mt: 2 }}
          />
          <Box sx={{ mt: 3, display: 'flex', justifyContent: 'space-between', color: 'primary' }}>
            <Button onClick={closeModal} variant="contained">
              Cancel
            </Button>
            <Button onClick={handleChangePassword} variant="contained" sx={{ bgcolor: '#a52a2a' }}>
              Submit
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default AdminManageUsers;
