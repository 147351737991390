// components/newslettersignup.js
import React, { useState, useEffect } from 'react';
import { Box, Typography, TextField, Button, Snackbar, Alert } from '@mui/material';
import axiosInstance from '../api/axiosInstance';

const NewsletterSignup = () => {
  const [subscribed, setSubscribed] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [showToast, setShowToast] = useState(false);
  const [toastSeverity, setToastSeverity] = useState('success');

  useEffect(() => {
    const hasSubscribed = localStorage.getItem('hasSubscribed');
    if (hasSubscribed) {
      setSubscribed(true);
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const name = e.target.elements.name.value;
    const email = e.target.elements.email.value;

    try {
      await axiosInstance.post('/newsletter', { name, email });
      setSubscribed(true);
      localStorage.setItem('hasSubscribed', 'true');
      setToastMessage('Subscribed successfully!');
      setShowToast(true);
      setToastSeverity('success');
      setTimeout(() => setShowToast(false), 3000);
    } catch (error) {
      if (error.response && error.response.status === 400) {
        // Handle the "already subscribed" error
        const message = error.response.data?.message || 'Already subscribed';
        if (message.includes('already subscribed')) {
          setSubscribed(true);
          localStorage.setItem('hasSubscribed', 'true');
          setToastMessage("Thank you, you've already subscribed!");
          setShowToast(true);
          setToastSeverity('error');
          setTimeout(() => setShowToast(false), 3000);
        }
      } else {
        setToastMessage('Something went wrong. Please try again.');
        setShowToast(true);
        setToastSeverity('error');
        setTimeout(() => setShowToast(false), 3000);
      }
    }
  };

  return (
    <>
      {!subscribed ? (
        <Box sx={{ mb: 2 }}>
          <Typography
            variant="h6"
            sx={{
              fontFamily: 'Cinzel, serif',
              fontWeight: 'bold',
              mb: 1,
              fontSize: { xs: '1em', md: '1.2em' },
            }}
          >
            SCRIBE <span style={{ color: '#a52a2a' }}>&</span> SCROLL NEWSLETTER
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: 1,
            }}
          >
            <TextField
              name="name"
              placeholder="Name"
              required
              sx={{
                width: '70%',
                maxWidth: 250,
                bgcolor: '#fff',
                borderRadius: 1,
                fontSize: { xs: '0.7em', md: '0.8em' },
                py: 0.5,
              }}
              inputProps={{
                sx: {
                  padding: '4px 8px',
                  fontSize: { xs: '0.7em', md: '0.8em' },
                },
              }}
            />
            <TextField
              name="email"
              type="email"
              placeholder="Email"
              required
              sx={{
                width: '70%',
                maxWidth: 250,
                bgcolor: '#fff',
                borderRadius: 1,
                fontSize: { xs: '0.7em', md: '0.8em' },
                py: 0.5,
              }}
              inputProps={{
                sx: {
                  padding: '4px 8px',
                  fontSize: { xs: '0.7em', md: '0.8em' },
                },
              }}
            />
            <Button
              type="submit"
              variant="contained"
              sx={{
                fontFamily: 'Cinzel, serif',
                fontWeight: 'bold',
                bgcolor: '#a52a2a',
                fontSize: { xs: '0.8em', md: '0.9em' },
                '&:hover': { bgcolor: '#8b0000' },
                mt: 1,
                px: 1.5,
                py: 0.5,
              }}
            >
              Subscribe
            </Button>
          </Box>
        </Box>
      ) : (
        <Box sx={{ bgcolor: '#efefee', py: 0.5 }}>
          <Typography
            sx={{
              fontFamily: 'Cinzel, serif',
              fontSize: { xs: '.9em', md: '1.1em' },
              fontWeight: 'bold',
              color: '#000',
            }}
          >
            SCRIBE <span style={{ color: '#a52a2a' }}>&</span> SCROLL WRITING
          </Typography>
          <Typography
            sx={{
              fontFamily: 'Cinzel, serif',
              fontSize: { xs: '.8em', md: '0.9em' },
              fontWeight: 'bold',
              color: '#000',
            }}
          >
            CREATIVE <span style={{ color: '#a52a2a' }}>CHRISTIAN</span> CONTENT
          </Typography>
        </Box>
      )}
            <Snackbar
            open={showToast}
            autoHideDuration={3000}
            onClose={() => setShowToast(false)}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }} // Adjust position here
            >
            <Alert onClose={() => setShowToast(false)} severity={toastSeverity} sx={{ width: '100%' }}>
                {toastMessage}
            </Alert>
            </Snackbar>
    </>
  );
};

export default NewsletterSignup;
