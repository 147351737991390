//Pages/Newsletter.js
import React, { useState, useEffect } from 'react';
import { Box, Paper, Typography, Divider, List, ListItem, ListItemText, Container } from '@mui/material';
import axiosInstance from '../api/axiosInstance';
import TrackPageView from '../components/TrackPageView'; // Import TrackPageView
import NewsletterArchives from '../assets/NewsletterArchives.png';

const NewsletterMenu = ({ setContentLoaded }) => {
  const [groupedNewsletters, setGroupedNewsletters] = useState({});
  const [selectedNewsletter, setSelectedNewsletter] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);  // Track selected date

  // Fetch newsletters and group by month/year
  useEffect(() => {
    const fetchNewsletters = async () => {
      setContentLoaded(false); // Reset loading state at the start
      try {
        const response = await axiosInstance.get('/newslettercontent');
        const newsletters = response.data;

        // Filter out newsletters that are inactive
        const activeNewsletters = newsletters.filter(newsletter => newsletter.is_active);

        // Group newsletters by month/year (e.g., December 2024, November 2024)
        const grouped = activeNewsletters.reduce((acc, newsletter) => {
          const publishDate = new Date(newsletter.publish_date);
          const monthYear = `${publishDate.toLocaleString('default', { month: 'long' })} ${publishDate.getFullYear()}`;

          if (!acc[monthYear]) {
            acc[monthYear] = [];
          }
          acc[monthYear].push(newsletter);
          return acc;
        }, {});

        // Sort grouped dates from newest to oldest
        const sortedGroupedNewsletters = Object.keys(grouped)
          .sort((a, b) => {
            const dateA = new Date(a.split(' ')[1], new Date(Date.parse(a.split(' ')[0] + ' 1, 2020')).getMonth());
            const dateB = new Date(b.split(' ')[1], new Date(Date.parse(b.split(' ')[0] + ' 1, 2020')).getMonth());
            return dateB - dateA;  // Newest first
          })
          .reduce((sortedObj, key) => {
            sortedObj[key] = grouped[key];
            return sortedObj;
          }, {});
          
        setGroupedNewsletters(sortedGroupedNewsletters);
        setContentLoaded(true);
        const mostRecentDate = Object.keys(sortedGroupedNewsletters)[0]; // Get the most recent date
        const mostRecentNewsletter = sortedGroupedNewsletters[mostRecentDate][0]; // Get the first (and only) newsletter for that date
        setSelectedNewsletter(mostRecentNewsletter);
        setSelectedDate(mostRecentDate); // Set the most recent date as selected

      } catch (error) {
        console.error('Failed to fetch newsletters:', error);
      }
    };
    fetchNewsletters();
}, [setContentLoaded]);


  // Function to fetch content when a user selects a month
  const fetchNewsletterContent = async (monthYear) => {
    const selected = groupedNewsletters[monthYear][0]; // Get the first (and only) newsletter for the selected month
    setSelectedNewsletter(selected);
    setSelectedDate(monthYear);  // Set the selected date
  };

  // Helper function to format the publish date
  const formatPublishDate = (dateString) => {
    const date = new Date(dateString);
    return `${date.toLocaleString('default', { month: 'long' })} ${date.getDate()}, ${date.getFullYear()}`;
  };

  return (
      <Container maxWidth="xl" sx={{ mt: 2, px: 2, fontFamily: 'Cinzel, serif', bgcolor: '#fff' }}>
        <TrackPageView pageName="Newsletter" />
        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' } }}>
          <Box
            sx={{
              width: { xs: '100%', md: '250px' }, // Full width on mobile, fixed width on larger screens
              mr: { md: 3 },
              mb: { xs: 3, md: 0 }, // Add margin at the bottom for mobile
            }}
          >
            <Paper elevation={2} sx={{ p: 2 }}>
            <Box
                component="img"
                src={NewsletterArchives}
                alt="Scribe & Scroll Newsletter"
                />
              <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1, fontFamily: 'Cinzel, serif', fontSize: '1.1rem' }}>
                Newsletter Archives
              </Typography>
              <Divider />
              <List
                  sx={{
                    fontFamily: 'Cinzel, serif',
                    cursor: 'pointer',
                    overflowY: 'auto', // Allows scrolling if content overflows
                    maxHeight: '70vh', // Prevents the list from taking up too much vertical space
                  }}
                >
                {Object.keys(groupedNewsletters).map((date) => (
                  <Box sx={{
                    fontFamily: 'Cinzel, serif',
                    '@media (max-width: 600px)': {
                      width: '100%', 
                    },
                  }}
                  key={date}>
                    <ListItem button onClick={() => fetchNewsletterContent(date)}
                      sx={{
                        fontFamily: 'Cinzel, serif',
                        borderRadius: '12px',
                        mb: .25,
                        backgroundColor: selectedDate === date ? '#efefee' : 'transparent',  // White background when selected
                        fontWeight: selectedDate === date ? 'bold' : 'normal',  // Bold text for selected item
                        color: selectedDate === date ?'#a52a2a' : '#000',
                        bgcolor: selectedDate === date ? '#ffffff !important' : 'transparent',
                        borderRight: selectedDate === date ? '3px solid #a52a2a' : '3px solid transparent',
                        borderLeft: selectedDate === date ? '3px solid #a52a2a' : '3px solid transparent',
                        boxShadow: selectedDate === date ? '3' : '',
                        '&:hover': { bgcolor: '#ffffff', color: selectedDate === date ?'#a52a2a' : '#000', 
                          fontWeight: selectedDate === date ? 'medium' : 'normal', 
                          borderLeft: selectedDate === date ? '3px solid #a52a2a' : '3px solid black', 
                          borderRight: selectedDate === date ? '3px solid #a52a2a' : '3px solid black', 
                          boxShadow: selectedDate === date ? '3' : '4',},
                        '@media (max-width: 600px)': {
                        fontSize: '0.75rem', 
                        py: 0.25, 
                      },         
                      }}
                    >
                      <ListItemText sx={{ fontFamily: 'Cinzel, serif', fontWeight: selectedDate === date ? 'bold' : 'normal', }} 
                      primary={date} /> 
                    </ListItem>
                  </Box>
                ))}
              </List>
            </Paper>
          </Box>

          {/* Newsletter Content Display */}
          <Box sx={{ flex: 1 }}>
            {selectedNewsletter ? (
              <Paper sx={{ p: 2 }}>
                {/* Display the publish date above the content */}
                <Typography align="left" variant="subtitle1" sx={{ fontFamily: 'Cinzel, serif', fontWeight: 'bold', color: '#a52a2a' }}>
                  Published on: {formatPublishDate(selectedNewsletter.publish_date)}
                </Typography>
                <Box
                  component="main"
                  dangerouslySetInnerHTML={{ __html: selectedNewsletter.newsletter_content }}
                  sx={{
                    fontSize: { xs: '1rem', sm: '1.1rem' },
                    fontFamily: 'Cinzel, serif',
                    lineHeight: 1.6,
                    '& h1, & h2': {
                      color: '#a52a2a',
                      fontSize: { xs: '1.5rem', sm: '2rem' },
                      mt: 2,
                    },
                    '& p': {
                      my: 2,
                      textAlign: { xs: 'center', sm: 'left' },
                    },
                    '& ul': {
                      paddingLeft: 3,
                      '& li': {
                        mb: 1,
                        fontWeight: 'bold',
                      },
                    },
                    '& img': {
                      maxWidth: '100%',  // Ensure images scale down on smaller screens
                      height: 'auto',  // Maintain aspect ratio
                      display: 'block',
                      margin: '20px auto',
                    },
                  }}
                />
              </Paper>
            ) : (
              <Typography
                sx={{
                  p: 8,
                  fontFamily: 'Cinzel, serif',
                  fontWeight: 'bold',
                  color: '#a52a2a',
                  fontSize: { xs: '1rem', md: '1.2rem' }, // Adjust font size based on screen size
                }}
                variant="body1"
              >
                Please select a newsletter to view its content.
              </Typography>
            )}
          </Box>
        </Box>
      </Container>
  );
};

export default NewsletterMenu;
