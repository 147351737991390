// components/AnnouncementModal.js
import React, { useState, useEffect } from 'react';
import { Box, Typography, IconButton, useMediaQuery, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useLocation } from 'react-router-dom';
import axiosInstance from '../api/axiosInstance';

const AnnouncementModal = () => {
    const [announcement, setAnnouncement] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const location = useLocation(); // Track current page location
    const isMobile = useMediaQuery('(max-width:600px)'); // Detect small screens

    useEffect(() => {
//        const allowedRoutes = ['/', '/home', '/free-ebook', '/write', '/engage', '/blog', '/blogs', '/about'];
//        changed to disallowed routes to ensure it shows on dynamically crated pages
          const disallowedRoutes = ['/login', '/forgot-password', '/reset-password', '/privacy-terms', '/dashboard', '/dashboard/*',
             '/dashboard/write-blog', '/dashboard/adminannouncements', '/dashboard/newsletter-emails', 
             '/dashboard/adminanalytics', '/dashboard/admininterface', '/dashboard/adminsitecontenteditor', '/dashboard/customercontact'];


        const fetchAnnouncement = async () => {
            try {
                const response = await axiosInstance.get('/announcements');
                const announcements = response.data;

                const unseenAnnouncement = announcements.find(
                    (a) =>
                        a.is_active &&
                        !localStorage.getItem(`hasSeenAnnouncement_${a.id}`)
                );

                if (unseenAnnouncement) {
                    setAnnouncement(unseenAnnouncement);
                    setShowModal(true);
                } else {
                    setShowModal(false);
                }
            } catch (error) {
                console.error('Failed to fetch announcements:', error);
            }
        };

        if (disallowedRoutes.includes(location.pathname)) {
            setShowModal(false);
        } else {
            fetchAnnouncement();
        }
    }, [location]);

    const handleDismiss = () => {
        if (announcement) {
            localStorage.setItem(`hasSeenAnnouncement_${announcement.id}`, 'true');
        }
        setShowModal(false);
    };

    if (!showModal || !announcement) {
        return null;
    }

    return (
        <Box
            sx={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                bgcolor: 'rgba(0, 0, 0, 0.5)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 1000,
            }}
            onClick={(e) => {
                if (e.target === e.currentTarget) setShowModal(false);
            }}
        >
            <Box
                sx={{
                    bgcolor: '#fff',
                    borderRadius: 2,
                    width: isMobile ? '85%' : '90%',
                    maxWidth: isMobile ? '350px' : '500px',
                    padding: 3,
                    boxShadow: 3,
                    position: 'relative',
                }}
            >
                {/* Close Icon */}
                <IconButton
                    onClick={() => setShowModal(false)}
                    sx={{
                        position: 'absolute',
                        top: 8,
                        right: 8,
                    }}
                >
                    <CloseIcon />
                </IconButton>

                {/* Announcement Content */}
                <Typography
                    variant="h6"
                    sx={{
                        fontFamily: 'Cinzel, serif',
                        textAlign: 'center',
                        mb: 2,
                        fontSize: isMobile ? '1.2rem' : '1.5rem',
                    }}
                >
                    {announcement.title || 'Announcement'}
                </Typography>

                <Box
                    dangerouslySetInnerHTML={{ __html: announcement.message || '' }}
                    sx={{
                        fontFamily: 'Cinzel, serif',
                        textAlign: 'center',
                        mb: 3,
                        '& img': {
                            maxWidth: '100%',
                            height: 'auto',
                        },
                    }}
                />

                {/* Dismiss Button */}
                <Box display="flex" justifyContent="center">
                    <Button onClick={handleDismiss} 
                    variant="outlined" sx={{
                        fontFamily: 'Cinzel, serif',
                        fontWeight: 'bold',
                        color: '#a52a2a',
                        borderColor: '#a52a2a',
                        fontSize: '0.8em',
                        py: 0.5,
                        '&:hover': {
                          color: '#fff',
                          borderColor: '#a52a2a',
                          backgroundColor: '#a52a2a',
                        },
                      }}>
                Dismiss
                </Button>
                </Box>
            </Box>
        </Box>
    );
};

export default AnnouncementModal;
