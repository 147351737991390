//pages/engage.js
import React, { useEffect, useState } from 'react';
import axiosInstance from '../api/axiosInstance';
import { Container, Typography, Box, Grid, Card, CardActionArea, CardContent, CardMedia } from '@mui/material';
import TrackPageView from '../components/TrackPageView'; // Import TrackPageView

const Engage = ({ setContentLoaded }) => {
  const [blogs, setBlogs] = useState([]);
  const [pageContent, setPageContent] = useState({ body: '' });

  useEffect(() => {
    const fetchPageContent = async () => {
      setContentLoaded(false); // Reset loading state at the start
      try {
        // Fetch the static content for the Engage page
        const response = await axiosInstance.get('/content/engage');
        setPageContent({ body: response.data.content || '' });
        setContentLoaded(true);
      } catch (error) {
        console.error('Failed to fetch content for Engage page:', error);
      }
    };

    const fetchBlogs = async () => {
      try {
        // Fetch the blogs associated with the Engage page
        const response = await axiosInstance.get('/blogs');
        const activeBlogs = response.data.filter((blog) => blog.status);
        setBlogs(activeBlogs);
      } catch (error) {
        console.error('Failed to fetch blogs:', error);
      }
    };

    fetchPageContent();
    fetchBlogs();
  }, [setContentLoaded]);

  const handleCardClick = (url) => {
    window.location.href = `/engage/${url}`;
  };

  return (
    <Container maxWidth="xl" sx={{ mt: 2, px: 2, fontFamily: 'Cinzel, serif', bgcolor: '#fff' }}>
      <TrackPageView pageName="Engage" />
      <Box
        component="section"
        dangerouslySetInnerHTML={{ __html: pageContent.body }}
        sx={{
          fontSize: { xs: '1rem', sm: '1.1rem' },
          fontFamily: 'Cinzel, serif',
          lineHeight: 1.6,
          '& h1, & h2, & h4': {
            color: '#a52a2a',
            textAlign: 'center',
            fontSize: { xs: '1.5rem', sm: '2rem' },
            mt: 2,
          },
          '& p': {
            my: 2,
            textAlign: { xs: 'center', sm: 'left' },
          },
        }}
      />

      {/* Blog Cards */}
      <Grid container spacing={2} justifyContent="center">
        {blogs.map((blog) => (
          <Grid item xs={12} sm={6} md={4} key={blog.id}>
            <Card
              onClick={() => handleCardClick(blog.url)}
              sx={{
                width: '100%',
                height: 250,
                position: 'relative',
                overflow: 'hidden',
                bgcolor: '#272727',
                color: '#ffffff',
                cursor: 'pointer',
                transition: 'transform 0.3s ease',
                '&:hover': {
                  transform: 'scale(1.05)',
                  boxShadow: 3,
                },
              }}
            >
              <CardActionArea sx={{ height: '100%' }}>
                <CardMedia
                  component="img"
                  image={blog.image || 'default-image.jpg'}
                  alt={blog.name}
                  sx={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                  }}
                />
                <CardContent
                  sx={{
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    width: '100%',
                    bgcolor: 'rgba(0, 0, 0, 0.6)',
                    color: '#ffffff',
                    textAlign: 'center',
                    py: 1,
                    px: 1,
                    zIndex: 1,
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      fontSize: '1em',
                      fontWeight: 'bold',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {blog.name.length > 50 ? `${blog.name.slice(0, 50)}...` : blog.name}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      fontSize: '0.8em',
                      mt: 0.5,
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {new Date(blog.created_at).toLocaleDateString()}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default Engage;
