// components/useUser.js
import { useState, useEffect } from 'react';
import axiosInstance from '../api/axiosInstance';

export const useUser = () => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await axiosInstance.get('/users/me');
        setUser(response.data);
        //console.log("hooks user response.data",response.data)
      } catch (error) {
        console.error('Error fetching user data:', error);
        setUser(null);
      }
    };

    fetchUser();
  }, []);

  return user;
};
